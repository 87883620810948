import React from "react";
import BannerImg from "../../assets/images/partners.jpg";
import BGImg from "../../assets/images/join.jpg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SvgIcon from "../../assets/images/gov.svg";
import {Link} from 'react-router-dom'
import { Helmet } from "react-helmet";

export const Partners = () => {


  const ourPatnershipData = [
    {
      title: "Industry Leaders",
      description:
        "We partner with leading companies and organizations in the renewable energy sector to advance technological innovation, promote best practices, and accelerate the adoption of clean energy solutions on a global scale.",
    },
    {
      title: "Research Institutions",
      description:
        "Collaboration with esteemed research institutions and academic organizations allows us to stay at the forefront of renewable energy research and development, fostering innovation and driving continuous improvement in our technologies and processes.",
    },
    {
      title: "Government Agencies",
      description:
        "Working closely with government agencies and regulatory bodies, we advocate for supportive policies and incentives that incentivize renewable energy adoption, facilitate market growth, and create a conducive environment for sustainable development.",
    },
    {
      title: "Non-Profit Organizations",
      description:
        "We collaborate with non-profit organizations and NGOs dedicated to environmental conservation, climate action, and community empowerment, leveraging their expertise and networks to drive meaningful change and address pressing sustainability challenges.",
    },
    {
      title: "Local Communities",
      description:
        "Our partnerships with local communities are central to our commitment to social responsibility and inclusive development. By engaging with communities, we ensure that our projects are socially and culturally sensitive, and that they deliver tangible benefits to those most affected by our operations.",
    },
  ];
  const patnershipBenfits = [
    {
      title: "Shared Vision",
      description:
        "Our partners share our commitment to sustainability and renewable energy, aligning with our mission to create a cleaner, greener future for generations to come.",
    },
    {
      title: "Collaborative Innovation",
      description:
        "Through collaboration, we harness the collective creativity and expertise of our partners to drive innovation, solve complex challenges, and unlock new opportunities for growth and impact.",
    },
    {
      title: "Enhanced Impact",
      description:
        "By pooling our resources and capabilities, we amplify our collective impact, enabling us to reach more communities, deploy more projects, and achieve greater sustainability outcomes.",
    },
    {
      title: "Mutual Support",
      description:
        "We foster a culture of mutual support and cooperation among our partners, recognizing that together, we are stronger and better equipped to address the world's most pressing environmental and social issues.",
    },
  ];

  return (
    <div>
               <Helmet>
        <title>Partners</title>
        </Helmet>
      <section
        className="grid grid-cols-1 sm:grid-cols-2  h-full px-10"
        style={{ backgroundColor: "#f9fbf5" }}
      >
        <div className="md:py-10 sm:py-5  justify-center flex flex-col h-full">
          <h4 className="sub-text  font-semibold text-xs">Partners</h4>
          <div
            className="flex flex-col justify-between  gap-8"
            style={{ width: "100%" }}
          >
            <h2 className="text-left font-bold  lg:text-5xl/tight">
              Together with our partners,{" "}
              <span style={{ color: "#469d2c"}} className="lg:text-5xl/tight">Nilaithal</span> lights the
              path towards a brighter, greener future.
            </h2>
            {/* <p className="decription">
              It represents a clean, green source of energy. Solar power is a
              great way to reduce your carbon footprint.
            </p> */}
          </div>
        </div>
        <div className="justify-center flex flex-col h-full">
          <img src={BannerImg} alt="" style={{ objectFit: "contain" }} />
        </div>
      </section>
      <section className="my-10 flex flex-col items-center">
        {/* <h2 className="text-4xl text-center font-bold">Our Culture</h2> */}
        <div className="shadow-xl w-4/5 p-10 rounded-xl">
          <p className="text-center">
            <p className="text-green-500 font-medium">NILAITHAL</p> is
            proud to collaborate with a diverse network of partners who share
            our vision of creating a sustainable future powered by renewable
            energy. Together, we leverage our collective expertise, resources,
            and innovation to drive positive change and make a lasting impact on
            the world.
          </p>
        </div>
      </section>
      <section className="my-10 flex flex-col items-center px-10 gap-5">
        <h2 className="text-4xl text-center font-bold">Our Partnerships</h2>

        {/* <p className="text-center">At <span className="text-green-500 font-medium">NILAITHAL</span>, we foster a culture of innovation, integrity, and inclusivity. We believe in empowering our employees to reach their full potential and thrive in a supportive and collaborative work environment. Here, you'll have the opportunity to work alongside industry experts, contribute to cutting-edge projects, and make a real difference in the world. </p> */}

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2  gap-4 mt-5">
          {ourPatnershipData.map((obj, index) => (
            <div
              className={`p-4 flex flex-col gap-4 shadow-md rounded-md hover:border-green-500 hover:border-2 hover:textwhite transition-all ease-in border-2 ${index ===0 ? 'row-span-4 ' : 'row-span-2 col-span-1'}`}
              key={index}
            >
              <div className="flex flex-col items-start hover:text-white">
                {/* <CheckCircleIcon
                className="text-center"
                style={{ textAlign: "center", color: "#469d2c", fontSize: 40 }}
              /> */}
                <img
                  src={SvgIcon}
                  alt=""
                  style={{
                    fontSize: 12,
                    width: 50,
                    height: 40,
                    fill: "green",
                    color: "green",
                  }}
                />
              </div>
              <h5 className="font-bold text-xl">{obj.title}</h5>
              <p className="description">{obj.description}</p>
            </div>
          ))}
        </div>
      </section>
      <section className="my-10 flex flex-col items-center px-10 gap-5">
        <h2 className="text-4xl text-center font-bold">
          Benefits of Partnership
        </h2>

        {/* <p className="text-center">At <span className="text-green-500 font-medium">NILAITHAL</span>, we foster a culture of innovation, integrity, and inclusivity. We believe in empowering our employees to reach their full potential and thrive in a supportive and collaborative work environment. Here, you'll have the opportunity to work alongside industry experts, contribute to cutting-edge projects, and make a real difference in the world. </p> */}

        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-[1fr,1fr] lg:grid-cols-[1fr,1fr,1fr,1fr] gap-4 mt-5">
          {patnershipBenfits.map((obj, index) => (
            <div
              className="p-4 flex flex-col gap-4 shadow-md rounded-md hover:border-green-500 hover:border-2 hover:textwhite transition-all ease-in border-2"
              key={index}
            >
              <div className="flex flex-col items-start hover:text-white">
                {/* <CheckCircleIcon
                className="text-center"
                style={{ textAlign: "center", color: "#469d2c", fontSize: 40 }}
              /> */}
                <img
                  src={SvgIcon}
                  alt=""
                  style={{
                    fontSize: 12,
                    width: 50,
                    height: 40,
                    fill: "green",
                    color: "green",
                  }}
                />
              </div>
              <h5 className="font-bold text-xl">{obj.title}</h5>
              <p className="description">{obj.description}</p>
            </div>
          ))}
        </div>
      </section>
      {/* background:linear-gradient(rgba(0, 0, 0, 0.651),rgba(0, 0, 0, 0.463)),url('./assets/BG1.jpg') no-repeat; */}
      <section className="my-10 flex flex-col items-center px-10 gap-5">
        <h2 className="text-4xl text-center font-bold">Join Us</h2>

<div className="w-full ">
<div
          className="my-5 bg-red w-full  flex flex-col items-center justify-center text-white px-20 gap-5 py-10"
          style={{
            background: `linear-gradient(rgba(0, 0, 0, 0.651),rgba(0, 0, 0, 0.463)),url(${BGImg}) no-repeat`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <p className="text-center text-xl/normal text-white">
          We are always seeking new partners who share our passion for sustainability and our commitment to renewable energy. Whether you're a company, organization, institution, or community group, we invite you to join us on this journey towards a cleaner, greener future. 
          </p>
          <p className="text-center text-xl/normal text-white">
          If you're interested in exploring partnership opportunities with <span style={{color:'#469d2c'}} className="text-xl/normal">NILAITHAL</span>, please contact us to learn more about how we can collaborate to drive positive change together.  
          </p>

          <Link to={"/contact"} preventScrollReset={true}> <button style={{backgroundColor:'#469d2c'}} className="px-6 py-3 rounded-md font-bold">Join Now</button></Link>
        </div>

</div>

      </section>
    </div>
  );
};
