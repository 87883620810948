import React, { useState } from "react";
import phonepelogo from "../../../assets/images/phonepe.svg";
import UPI from "../../../assets/images/upi.png";
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";
// import { useScroll } from "framer-motion";

const Step2 = ({transactionData}) => {

  const [data,setData]=useState(transactionData);

  const[paymenturl,setPaymentUrl]=useState(null);

  const[loading,setLoading]=useState(false)


 


  const PostTransactiontoPhonePe = async (e)=>{
    e.preventDefault();
    try {
      setLoading(true)
     const response = await fetch('https://backend.nilaithal.com/api/payment/initiate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "merchantTransactionId": transactionData.merchantTransactionId,
                }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setLoading(false)

   

      // Assume the payment URL is in data.paymentUrl
      const paymentUrl = data.data.url;

      // Redirect to the payment URL
      window.location.href = paymentUrl;
    } catch (error) {
      console.error('There was a problem with the payment request:', error);
      setLoading(false)
      // Handle error appropriately
    } 
  };
 
    

  

  return (
    <>
      <div className="w xl:w-4/5 lg:w-full px-2  lg:px-5 border py-5 rounded-lg shadow-lg">
        <form onSubmit={PostTransactiontoPhonePe} className="flex flex-col gap-5">
          <div className="flex gap-2">
            <input type="radio" checked className="" />
            <p className="lg:text-xl md:text-xl text-xs font-bold">
              All UPI apps, Credit and Debit Cards accepted
            </p>
          </div>
          <div className="flex gap-4 items-center xl:flex-row lg:flex-row md:flex-row flex-col  border-l-8 border-[#582899] px-5 border rounded-lg py-5">
            <div className="">
              <h4 className="font-bold text-[#582899]  text-2xl">Pay Now</h4>
              <div className="flex items-center gap-5">
                <h6 className="text-gray-400 font-semibold">Powered by</h6>
                <div className="h-[25px] w-[60px]">
                  <img
                    src={phonepelogo}
                    alt=""
                    className="h-full w-full object-contain"
                  />
                </div>
              </div>
            </div>
            <div className="lg:border-l-2 md:border-l-2  border-black">
              <div className="h-[100px] w-[200px] lg:w-[300px] md:w-[300px]">
                <img
                  src={UPI}
                  alt=""
                  className="h-full w-full object-contain mx-2"
                />
              </div>
            </div>
          </div>
          <p className="text-[16px]">
            Your personal data will be used to process your order, support your
            experience throughout this website, and for other purposes described
            in{" "}
            <Link
              to="/privacy-policy"
              className="underline hover:text-green-500"
            >
              Our Policy
            </Link>
          </p>
          <button
            className={`w-full ${loading ? 'bg-gray-400':'bg-[#6b994b] hover:bg-black'} font-semibold text-white p-2 rounded-lg `}
            type="submit" disabled={loading}
          >
            { loading ? <span className="flex flex-row items-center justify-center gap-5"><CircularProgress size={18} color="inherit"/> Please Wait...</span>:<>₹ {data.totalAmount} Place Order</>}
          </button>
        </form>
      </div>
    </>
  );
};

export default Step2;
