import React from 'react'
import "./control.css"
import Slider, { SliderThumb } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import { FastForward, FastRewind, Pause, PlayArrow, SkipNext, VolumeOff, VolumeUp } from '@mui/icons-material'

const Controls = ({onPlayPause,playing,onForward,onRewind,played,onSeek,onSeekMouseUp,OnVolumeChangeHandler,onVolumeSeekUp,mute,onMute,onMouseSeekDown,currentTime,duration, controlref,volume,}) => {

    const PrettoSlider = styled(Slider)({
        color: '#52af77',
        height: 8,
        width:"100%",
        '& .MuiSlider-track': {
          border: 'none',
        },
        '& .MuiSlider-thumb': {
          height: 24,
          width: 24,
          backgroundColor: '#fff',
          border: '2px solid currentColor',
          '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
          },
          '&::before': {
            display: 'none',
          },
        },
        '& .MuiSlider-valueLabel': {
          lineHeight: 1.2,
          fontSize: 12,
          background: 'unset',
          padding: 0,
          width: 32,
          height: 32,
          borderRadius: '50% 50% 50% 0',
          backgroundColor: '#469d2c',
          transformOrigin: 'bottom left',
          transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
          '&::before': { display: 'none' },
          '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
          },
          '& > *': {
            transform: 'rotate(45deg)',
          },
        },
      });
      
  return (
    <div className="control_container" ref={controlref}>
        <div className="top_container">
            <h2>Video Player</h2>
        </div>
        <div className="mid_container">
            <div className="icon_btn" onDoubleClick={onRewind}>
                <FastRewind fontSize='medium'/>
            </div>
            <div className="icon_btn" onClick={onPlayPause}>
                {
                    playing ? (
                        <Pause fontSize='medium'/>
                        
                    ):(
                        <PlayArrow fontSize='medium'/>
                    )
                }
                {""}
                
            </div>
            <div className="icon_btn" onDoubleClick={onForward}>
                <FastForward fontSize='medium'/>
            </div>
            
        </div>
        <div className="bottom_container">
            <div className="slider_container">
                <PrettoSlider min={0} max={100} value={played * 100} onChange={onSeek} onChangeCommitted={onSeekMouseUp} onMouseDown={onMouseSeekDown} className='sm:w-11/12' />
            </div>
            <div className="control_box">
                <div className="inner_controls">

                <div className="icon_btn">
                    {/* <PlayArrow fontSize='medium'/> */}
                </div>
                <div className="icon_btn">
                    {/* <SkipNext fontSize='medium'/> */}
                </div>
                <div className="icon_btn " onClick={onMute}>
                    {
                        mute ? (
                            <VolumeOff fontSize='medium'/>
                        ):(
                            <VolumeUp fontSize='medium'/>
                        )
                    }
                    
                </div>
                <div className='flex flex-row gap-5 items-center'>
                <Slider sx={{width:100, color:'#469d2c'}} onChange={OnVolumeChangeHandler} value={volume * 100} onChangeCommitted={onVolumeSeekUp}/>
                <span className='text-xs text-green-600'>{currentTime}:{duration}</span>

                </div>

            </div>                  
            </div>
        </div>
    </div>
  )
}

export default Controls