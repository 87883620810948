import card from '@material-tailwind/react/theme/components/card';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

const Step1 = ({ billingData, products,handleNext, }) => {
  const [formData, setFormData] = useState({
    products: '',
    firstname: '',
    lastname: '',
    name: '',
    email: '',
    phone: '',
    streetaddress: '',
    houseno: '',
    state: '',
    city: '',
    pincode: '',
  });

  const [data,setData]=useState('')

  useEffect(()=>{
    const cookieData = JSON.parse(sessionStorage.getItem('cart'));

    setFormData({
      products:cookieData
    })

 

  },[])



 

  const states = [
    { value: "AN", label: "Andaman and Nicobar Islands" },
    { value: "AP", label: "Andhra Pradesh" },
    { value: "AR", label: "Arunachal Pradesh" },
    { value: "AS", label: "Assam" },
    { value: "BR", label: "Bihar" },
    { value: "CG", label: "Chandigarh" },
    { value: "CH", label: "Chhattisgarh" },
    { value: "DH", label: "Dadra and Nagar Haveli" },
    { value: "DD", label: "Daman and Diu" },
    { value: "DL", label: "Delhi" },
    { value: "GA", label: "Goa" },
    { value: "GJ", label: "Gujarat" },
    { value: "HR", label: "Haryana" },
    { value: "HP", label: "Himachal Pradesh" },
    { value: "JK", label: "Jammu and Kashmir" },
    { value: "JH", label: "Jharkhand" },
    { value: "KA", label: "Karnataka" },
    { value: "KL", label: "Kerala" },
    { value: "LD", label: "Lakshadweep" },
    { value: "MP", label: "Madhya Pradesh" },
    { value: "MH", label: "Maharashtra" },
    { value: "MN", label: "Manipur" },
    { value: "ML", label: "Meghalaya" },
    { value: "MZ", label: "Mizoram" },
    { value: "NL", label: "Nagaland" },
    { value: "OR", label: "Odisha" },
    { value: "PY", label: "Puducherry" },
    { value: "PB", label: "Punjab" },
    { value: "RJ", label: "Rajasthan" },
    { value: "SK", label: "Sikkim" },
    { value: "TN", label: "Tamil Nadu" },
    { value: "TS", label: "Telangana" },
    { value: "TR", label: "Tripura" },
    { value: "UK", label: "Uttarakhand" },
    { value: "UP", label: "Uttar Pradesh" },
    { value: "WB", label: "West Bengal" }
  ];

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        [name]: value,
      };
      if (name === 'firstname' || name === 'lastname') {
        updatedFormData.name = `${updatedFormData.firstname} ${updatedFormData.lastname}`.trim();
      }
      validateField(name, value);
      return updatedFormData;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Final validation before submitting
    const newErrors = {};
    for (const field in formData) {
      const error = validateField(field, formData[field]);
      if (error) {
        newErrors[field] = error;
      }
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

  
    
    try {
      const response = await fetch('https://backend.nilaithal.com/api/orders/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      if (response.ok) {
        const data = await response.json();
        setData(data);
        billingData(data)
        // updateStatus(response.status)
        // console.log(response.status)
        // console.log(statusCode,'hello world')
      
        // setformData({
        //   name: '',
        //   email: '',
        //   subject: '',
        //   phone: '',
        //   message: ''
        // });
        setErrors({});
      } else {
        // setSubmitted(true)
        console.error('Error:', response.statusText);
        // updateStatus(response.status)
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };


  const validateField = (name, value) => {
    let errorMessage = '';
    const nameRegex = /^[a-zA-Z\s]{1,50}$/;
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const phoneRegex = /^[0-9]{10}$/;
    const pincodeRegex = /^[0-9]{6}$/;

    switch (name) {
      case 'firstname':
        if (!nameRegex.test(value)) {
          errorMessage = 'Name must be alphabetic and up to 50 characters long';
        }
        break;
      case 'lastname':
        if (!nameRegex.test(value)) {
          errorMessage = 'Name must be alphabetic and up to 50 characters long';
        }
        break;
      case 'email':
        if (!emailRegex.test(value)) {
          errorMessage = 'Invalid email format';
        }
        break;
      case 'phone':
        if (!phoneRegex.test(value)) {
          errorMessage = 'Phone number must be 10 digits long';
        }
        break;
      case 'pincode':
        if (!pincodeRegex.test(value)) {
          errorMessage = 'PinCode number must be 6 digits long';
        }
        break;
      case 'streetaddress':
        if (!value) {
          errorMessage = 'Address is required';
        }
        break;
      case 'houseno':
        if (!value) {
          errorMessage = 'Field is required';
        }
        break;
      case 'state':
        if (!value) {
          errorMessage = 'State is required';
        }
        break;
      case 'city':
        if (!value) {
          errorMessage = 'City is required';
        }
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));
    return errorMessage;
  };

  return (
    <>
      <div className="py-5 px-2 lg:px-10 xl:w-full md:w-full md:px-10">
        <form  className="flex flex-col gap-4" onSubmit={handleSubmit}>
          <div className="flex flex-col gap-5">
            <h3 className="font-bold text-2xl">Contact</h3>
            <input
              type="email"
              id="email"
              name="email"
              required
              placeholder="johndoe@gmail.com"
              onChange={handleChange}
              className="bg-white py-4 px-8 rounded-md focus:outline-green-500 leading-tight lg:w-full md:w-full w-full border-2 "
            />
            {errors.email && (
              <span className="text-[10px] text-red-700 font-semibold">
                {errors.email}
              </span>
            )}
          </div>
          <div className="flex flex-col gap-5">
            <h3 className="font-bold text-2xl">Billing Address</h3>

            <select
              name="state"
              id="state"
              className="w-full border-2 py-4 px-8 rounded-md focus:outline-green-500"
              onChange={handleChange}
              required
            >
              <option
                value=""
                className="hover:bg-green-500"
                disabled
                hidden
                selected
              >
                Select State
              </option>
              {states.map((obj, index) => (
                <option value={obj.label} key={index} className="hover:bg-green-500">{obj.label}</option>
              ))}
            </select>
            {errors.state && (
              <span className="text-[10px] text-red-700 font-semibold">
                {errors.state}
              </span>
            )}
            <div className="flex flex-col gap-5">
              <div className="flex flex-row gap-5">
                <input
                  type="text"
                  name="firstname"
                  id="firstname"
                  placeholder="First Name"
                  onChange={handleChange}
                  className="bg-white py-4 px-8 rounded-md focus:outline-green-500 leading-tight lg:w-1/2 md:w-1/2 w-full border-2 "
                />
                {errors.firstname && (
                  <span className="text-[10px] text-red-700 font-semibold">
                    {errors.firstname}
                  </span>
                )}
                <input
                  type="text"
                  name="lastname"
                  id="lastname"
                  placeholder="Last Name"
                  onChange={handleChange}
                  className="bg-white py-4 px-8 rounded-md focus:outline-green-500 leading-tight lg:w-1/2 md:w-1/2 w-full border-2 "
                />
                {errors.lastname && (
                  <span className="text-[10px] text-red-700 font-semibold">
                    {errors.lastname}
                  </span>
                )}
              </div>
              <input
                type="text"
                id="streetaddress"
                name="streetaddress"
                onChange={handleChange}
                placeholder="Address"
                className="bg-white py-4 px-8 rounded-md focus:outline-green-500 leading-tight  w-full border-2 "
              />
              {errors.streetaddress && (
                <span className="text-[10px] text-red-700 font-semibold">
                  {errors.streetaddress}
                </span>
              )}
              <input
                type="text"
                id="houseno"
                name="houseno"
                onChange={handleChange}
                placeholder="Apartment, suite, etc."
                className="bg-white py-4 px-8 rounded-md focus:outline-green-500 leading-tight  w-full border-2 "
              />
              {errors.houseno && (
                <span className="text-[10px] text-red-700 font-semibold">
                  {errors.houseno}
                </span>
              )}
              <input
                type="number"
                id="phone"
                name="phone"
                onChange={handleChange}
                placeholder="Mobile Number"
                className="bg-white py-4 px-8 rounded-md focus:outline-green-500 leading-tight  w-full border-2 "
              />
              {errors.phone && (
                <span className="text-[10px] text-red-700 font-semibold">
                  {errors.phone}
                </span>
              )}

              <div className="flex flex-row gap-5">
                <input
                  type="text"
                  placeholder="City"
                  onChange={handleChange}
                  name="city"
                  id="city"
                  className="bg-white py-4 px-8 rounded-md focus:outline-green-500 leading-tight lg:w-1/2 md:w-1/2 w-full border-2 "
                />
                {errors.city && (
                  <span className="text-[10px] text-red-700 font-semibold">
                    {errors.city}
                  </span>
                )}
                <input
                  type="number"
                  placeholder="Pin Code"
                  name="pincode"
                  id="pincode"
                  onChange={handleChange}
                  className="bg-white py-4 px-8 rounded-md focus:outline-green-500 leading-tight lg:w-1/2 md:w-1/2 w-full border-2 "
                />
                {errors.pincode && (
                  <span className="text-[10px] text-red-700 font-semibold">
                    {errors.pincode}
                  </span>
                )}
              </div>
            </div>
            <button
              className="w-full bg-[#6b994b] font-semibold text-white p-2 rounded-lg hover:bg-black"
              type='submit'
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Step1;
