import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import ImageCarousel from '../../../../Components/Carousel'
import VideoPlayer from '../../../../Components/VideoPlayer'

const ServiceDetails = () => {
    const data = useLocation()

    // console.log(data.state,'helo')
    

    const {id,title,description,architecturalPlan,cardImg,BannerImg,orientation,planningDescription,sliderImg,video}=data.state
  return (
    <div>
        <section className='flex flex-col items-center justify-center' style={{
            background: `linear-gradient(rgba(0, 0, 0, 0.651),rgba(0, 0, 0, 0.463)),url(${BannerImg}) no-repeat`,
            minHeight:'40vh',
            backgroundPosition:'center',
            backgroundSize:'cover'
        }}>
            <h2 className='text-white stroke-white lg:text-5xl sm:text-2xl font-bold uppercase'>{title}</h2>
            {/* {
                sliderImg.map((obj)=>(
                    <img src={obj} alt="" />
                ))
            } */}

        </section>

        <section className='grid grid-cols-1 lg:grid-cols-2 px-10 my-10 gap-5'>
            <ImageCarousel images={sliderImg}/>
            <div className='flex flex-col gap-10'>

                <h2 className='font-bold text-4xl'>Architectural Planning</h2>
                <p className='description' style={{}}>{architecturalPlan}</p>

            </div>
        </section>


        <section className='flex flex-col items-center justify-center my-10 px-10 gap-10'>

        <h2 className='font-bold text-4xl'>Planning the environment</h2>
        <p className='description text-justify' style={{}}>{architecturalPlan}</p>

        <VideoPlayer videofile={video} className="lg:block md:block sm:hidden"/>

        </section>

        <section className='flex flex-col gap-5 px-10 my-10 items-center'>
        <h2 className='font-bold text-4xl'>Orientation</h2>
        <p className='description text-justify' style={{}}>{orientation}</p>

        </section>
        
        
        </div>
  )
}

export default ServiceDetails