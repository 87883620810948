import React, { useEffect, useState } from "react";
import SliderComponent from "./SliderComponent";
import "./style.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Service1Img from "../../assets/images/service1.jpg";
import Sec2BgImg from "../../assets/images/homeS3.jpg";
import Sec2Img from "../../assets/images/home_s3.jpg";
import { Gallery } from "./FilterableGallery";
import { TeamMembers } from "../../Components/Team";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AccordionItem from "../../Components/Accordion";
import { Link, useNavigate } from "react-router-dom";
import WindIMG from "../../assets/images/service1.jpg";
import SolarIMG from "../../assets/images/service2.jpg";
import EVIMG from "../../assets/images/evbg.jpg";
import WindBannerIMG from '../../assets/images/windbg.jpg'
import SolarBannerIMG from '../../assets/images/solarimg.jpg'
import EvBannerIMG from '../../assets/images/EVBanner.jpg'
import WindSlide1 from '../../assets/images/bg1.jpg'
import WindSlide2 from '../../assets/images/single_service_slide_1.jpg'
import SolarSlide1 from '../../assets/images/bg2.jpg'
import SolarSlide2 from '../../assets/images/service2.jpg'
import EVSlide1 from '../../assets/images/ev.jpg'
import EVSlide2 from '../../assets/images/evbg.jpg'
import OverlayBg from '../../assets/images/cta_base.png'
import { AnimatePresence, motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Helmet } from "react-helmet";


export const Home = () => {

  const [faqs,setFaqs]=useState([
    {
      id:1,
      question: "What products does Nilaithal offer?",
      answer:
        "We offer a range of products including wind turbines, solar panels, electric vehicle (EV) chargers, and India1 Charger - EV charging station aggregator apps.",
        open:true
    },
    {
      id:2,
      question: "How does Nilaithal contribute to sustainability?",
      answer:
        "Nilaithal is dedicated to transforming the energy landscape by harnessing renewable resources, reducing reliance on fossil fuels, and minimizing environmental impact.",
        open:false
    },
    {
      id:3,
      question: "Are Nilaithal's products suitable for residential use?",
      answer:
        "Yes, our products are designed for both residential and commercial applications, offering clean and efficient energy solutions for various needs.",
        open:false
    },
    {
      id:4,
      question: "Is financing available for Nilaithal's products?",
      answer:
        "Yes, we offer financing options to make our products more accessible to customers interested in transitioning to renewable energy.",
        open:false
    },
    {
      id:5,
      question: "Does Nilaithal provide installation services?",
      answer:
        "Yes, we offer installation services for our products to ensure optimal performance and efficiency.",
        open:false
    },
    {
      id:6,
      question: "How can I purchase Nilaithal's products?",
      answer:
        "You can contact us directly through our website or get in touch with our authorized distributors for purchasing inquiries.",
        open:false
    },
  ])



  const serviceData = [
    {
      id: 1,
      title: "EV Stations",
      description:
        "Electric Vehicle (EV) stations provide a convenient and sustainable way to charge electric cars, contributing to the growth of the green transportation infrastructure.",
      cardImg: EVIMG,
      BannerImg: EvBannerIMG,
      architecturalPlan:
        "Designing EV stations involves considerations such as site layout, access for vehicles, and integration with existing infrastructure. Architects focus on creating efficient charging spaces that are accessible, safe, and visually appealing.",
      sliderImg: [EVSlide1, EVSlide2],
      planningDescription:
        "Planning for EV stations includes assessing power supply requirements, determining the number and type of charging points, and considering future expansion needs. Integration with renewable energy sources and smart grid technologies can enhance sustainability and efficiency.",
      video: "https://videos.pexels.com/video-files/4817952/4817952-uhd_3840_2160_30fps.mp4",
      orientation:
        "The orientation of EV charging stations should prioritize easy access for vehicles while ensuring efficient use of space. Considerations such as traffic flow, parking layout, and pedestrian safety are important factors in the design process.",
    },

    {
      id: 2,
      title: "Solar Panels",
      description:
        "Solar panels harness the power of the sun to generate electricity, offering a sustainable and renewable energy solution for homes and businesses.",
      cardImg: SolarIMG,
      BannerImg: SolarBannerIMG,
      architecturalPlan:
        "Incorporating solar panels into architectural designs involves careful consideration of orientation and placement to maximize exposure to sunlight. This not only optimizes energy generation but also integrates the panels seamlessly into the building's aesthetic.",
      sliderImg: [SolarSlide1, SolarSlide2],
      planningDescription:
        "Planning for solar panel installations includes assessing the available sunlight, determining the optimal tilt and orientation of the panels, and considering any shading obstacles. Integration with the building's electrical system and grid connection are also key considerations.",
      video: "https://videos.pexels.com/video-files/4034091/4034091-uhd_3840_2160_30fps.mp4",
      orientation:
        "The orientation of solar panels is crucial for maximizing energy production. In the Northern Hemisphere, panels typically face south to capture the most sunlight throughout the day. Proper orientation ensures efficient conversion of solar energy into electricity.",
    },
    {
      id: 3,
      title: "Wind Turbines",
      description:
        "It represents a clean, green source of energy. Solar power is a great way to reduce your carbon footprint.",
      cardImg: WindIMG,
      BannerImg: WindBannerIMG,
      architecturalPlan:
        "The architect usually begins to work when the site and the type and cost of a building have been determined. The site involves the varying behaviour of the natural environment that must be adjusted to the unvarying physical needs of human beings; the type is the generalized form established by society that must be adjusted to the special use for which the building is required; the cost implies the economics of land, labour, and materials that must be adjusted to suit a particular sum.force each other: socio-cultural value, experiental value, building-technical value and economical value.",
      sliderImg: [WindSlide1, WindSlide2],
      planningDescription:
        "The methods of controlling the environment considered here are only the practical aspects of planning. They are treated by the architect within the context of the expressive aspects. The placement and form of buildings in relation to their sites, the distribution of spaces within buildings, and other planning devices discussed below are fundamental elements in the aesthetics of architecture.",
      video: "https://videos.pexels.com/video-files/3204058/3204058-hd_1920_1080_30fps.mp4",
      orientation:
        "The arrangement of the axes of buildings and their parts is a device for controlling the effects of sun, wind, and rainfall. The sun is regular in its course; it favours the southern and neglects the northern exposures of buildings in the Northern Hemisphere, so that it may be captured for heat or evaded for coolness by turning the axis of a plan toward or away from it.",
    },

  ];

 const navigate = useNavigate()


 const OnServiceClick = (data)=>{
  navigate('/service-details',{state:data})

  // console.log(data,'heere')
 }

  const [isHover, setIsHover] = useState(null);
  const handleClick = (clickedId) => {
    setFaqs((prevItems) =>
      prevItems.map((item) =>
        item.id === clickedId ? { ...item, open: true } : { ...item, open: false }
      )
    );
  };

  const control = useAnimation()

  const boxAnimation =  {
    visible:{opacity:1,scale:1,transition:{duration:0.5}},
    hidden:{opacity:0,scale:0.5}
  }
  return (
    <div>
              <Helmet>
        <title>Home</title>
        </Helmet>
      <SliderComponent />
      <section className="sec-1 my-10 md:mx-10">
        <h2 className="heading-title text-6xl text-center">Our Services</h2>
        <h4 className="sub-text text-center">What We do</h4>
        <div className="inner-col my-5">
          <h2 className="title text-5xl text-center">Save Energy Today</h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4 md:mx-10">
          <AnimatePresence>
          {serviceData.map((obj, index) => {
            return (
              <motion.div  variants={boxAnimation} initial="hidden" animate={control} whileInView="visible"
              key={index}
                className="  bg-gray-100 shadow-xl card p-16 md:w-full"
                style={isHover ===obj.id ? {
                  background: `linear-gradient(rgba(0, 0, 0, 0.651),rgba(0, 0, 0, 0.463)),url(${obj.cardImg}) no-repeat`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }:{}}
                onMouseEnter={()=>setIsHover(obj.id)}
                onMouseLeave={()=>setIsHover(null)}
              >
                <div className="inner-box">
                  <span className="service-count">0{obj.id} &nbsp;</span>
                  <span className="service-title">{obj.title}</span>
                  <p className="description">{obj.description}</p>
                </div>
                <div className="btn-content place-items-end w-full flex justify-end right-10 " onClick={()=>OnServiceClick(serviceData[index])}>
                  <span className="toggled-span">Learn More</span>
                  
                    <ArrowForwardIcon
                      style={{ backgroundColor: "#469d2c", fontSize: 40 }}
                      className="rounded-full text-white p-2 arrow-btn cursor-pointer"
                    />
                  
                </div>
              </motion.div>
            );
          })}
          </AnimatePresence>

        </div>
      </section>
      <section className="sec-2 flex flex-wrap my-5 h-full">
        <div className="col-1 md:px-10 sm:px-10 lg:px-10 px-10" style={{ backgroundColor: "#f9fbf5" }}>
          <h2 className="heading-title text-6xl">Investments</h2>
          <h4 className="sub-text animate-fadeinleft">About Company</h4>
          <div className="flex flex-col  gap-8" style={{ width: "100%" }}>
            <h2 className="title text-5xl text-left animate-fadeinleft">
              Invest In Our Company & Save the Planet
            </h2>
            <p className="description animate-fadein">
              At
              <span style={{ color: "#469d2c", fontWeight: "bold", fontSize:20 }}>
                NILAITHAL
              </span>
              , we believe in the power of renewable energy to transform the
              world. Founded on the principles of sustainability and innovation,
              we're committed to driving positive change through cutting-edge
              technology and forward-thinking solutions. Our team of experts is
              passionate about creating a brighter, cleaner future for
              generations to come.
            </p>
          </div>
          {/* <div
            className="progress-container my-5 flex flex-col gap-8"
            style={{ width: "90%" }}
          >
            <div className="inner-container flex gap-3 flex-row  justify-center items-center">
              <Box sx={{ width: "100%", mr: 1 }}>
                <p>Wind Energy</p>
                <LinearProgress
                  value={80}
                  variant="determinate"
                  sx={{ borderRadius: 10, height: 10 }}
                />
              </Box>
              <p>80%</p>
            </div>
            <div className="inner-container flex gap-3 flex-row  justify-center items-center">
              <Box sx={{ width: "100%", mr: 1 }}>
                <p>Solar Energy</p>
                <LinearProgress
                  value={89}
                  variant="determinate"
                  sx={{ borderRadius: 10, height: 10 }}
                />
              </Box>
              <p>80%</p>
            </div>
            <div className="inner-container flex gap-3 flex-row  justify-center items-center">
              <Box sx={{ width: "100%", mr: 1 }}>
                <p>EV PowerPoints</p>
                <LinearProgress
                  value={75}
                  variant="determinate"
                  sx={{ borderRadius: 10, height: 10 }}
                  color="primary"
                />
              </Box>
              <p>80%</p>
            </div>
          </div> */}
        </div>
        <div
          className="col-2 relative flex flex-col justify-center"
          style={{
            backgroundImage: `URL(${Sec2BgImg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize:'cover'
          }}
        >
          <div className="inner-img w-2/4 h-4/5">
            <img
              src={Sec2Img}
              alt=""
              style={{
                height: "100%",
                maxWidth: "100%",
                objectFit: "cover",
                textAlign: "left",
                marginLeft: -40,
              }}
            />
          </div>
        </div>
      </section>
      <section className="sec-3">
        <h2 className="heading-title text-6xl text-center">Best Works</h2>
        <h4 className="sub-text text-center">Our Portfolio</h4>
        <div className="inner-col my-5">
          <h2 className="title text-5xl text-center">Latest Projects</h2>
        </div>
        <Gallery />
      </section>
      <section className="items-center justify-center flex flex-col relative px-10 py-10" style={{
    
          background: `url(${OverlayBg}) no-repeat`,
          minHeight:'auto',
          backgroundPosition:'center',
          backgroundSize:'cover',
          backgroundColor: '#1b1b1b'
    
      }}>
        <h2
          className="heading-title text-center text-white absolute md:text-3xl sm:text-2xl opacity-10"
          style={{ WebkitTextStrokeColor: "#fff" }}
        >
          24/7
        </h2>
        <div className="overlay-bg grid grid-cols-1 lg:grid-cols-[2fr,1fr] items-center gap-5">
          <div className="flex flex-col gap-3  sm:w-full">
            <h3 className="text-white  font-bold md:text-3xl sm:text-xl">
            Power your inbox with the latest in renewable energy.
            </h3>
            <p className="text-white font-semibold md:text-xl sm:text-xs">
            Subscribe to Nilaithal's newsletter for exclusive updates, industry insights, and special offers. Stay ahead of the curve and join the clean energy revolution
            </p>
          </div>
          <div className="items-end flex justify-around">
            <button className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded transition-colors duration-300">
              Sign up now
            </button>
          </div>
        </div>
      </section>

      {/* <section className="sec-5">
        <h2 className="heading-title text-6xl text-center">Experts</h2>
        <h4 className="sub-text text-center">Creative Nerds</h4>
        <div className="inner-col my-5 flex flex-col gap-8 px-10">
          <h2 className="title text-5xl  text-center ">Meet Our Team</h2>
          <TeamMembers />
        </div>
      </section> */}
      
      <section
        className="grid grid-cols-1 sm:grid-cols-2 gap-4 px-10 "
        style={{ backgroundColor: "#f9fbf5"}}
      >
        <div className="md:px-10 justify-center flex flex-col ">
          <h2 className="heading-title text-6xl">FAQ</h2>
          <h4 className="sub-text">About Company</h4>
          <div
            className="flex flex-col justify-between  gap-8"
            style={{ width: "70%" }}
          >
            <h2 className="title text-5xl text-left">
              Frequently Asked Questions
            </h2>
            <p className="description">
              The most comprehensive complete-system warranty in the solar
              industry. Hassle-free replacement, repair or reimbursement.
            </p>
            <span style={{fontSize:17}}>
              Any Questions?{" "}
              <a href="mailto:milin@india1charger" style={{ color: "#469d2c" }}>
              milin@india1charger
              </a>
            </span>
          </div>
        </div>
        <div className="md:px-10 justify-center flex flex-col py-10">
          {faqs.map((item) => (
             <AccordionItem key={item.id} item={item} onClick={handleClick} />
          ))}
        </div>
      </section>
    </div>
  );
};
