import { CodeOutlined } from '@mui/icons-material'
import React from 'react'
import { useLocation } from 'react-router-dom'
import PersonPinIcon from '@mui/icons-material/PersonPin';
import EastIcon from '@mui/icons-material/East';
import { Helmet } from 'react-helmet';
const ViewJob = () => {

    const data = useLocation()

    const {id,category,title,location,type,description,requirements,responsibilities}=data.state

   

    
  return (
    <div>
                 <Helmet>
        <title>{title}</title>
        </Helmet>
       <section className='my-10 px-10 grid grid-cols-1 lg:grid-cols-[2fr,1fr] md:grid-cols-[2fr,1fr] gap-5 relative'>

        <div className='content_wrapper  flex flex-col gap-10' >

            <div className='flex flex-col gap-5'>
                <CodeOutlined fontSize='large'/>
                <h2 className='font-bold lg:text-5xl md:text-3xl sm:text-2xl leading-tight'>{title}</h2>
                <p className='text-xl leading-normal'>{description}</p>

                <div className="flex flex-row gap-5 items-center justify-start">
                    <h5>{location}</h5>
                    <p>{type}</p>
                </div>
                <br/>
                <hr className='text-grey-100 w-3/5' style={{height:2}}/>
            </div>
            <div className='flex flex-col gap-5'>
            <h2 className='font-bold lg:text-5xl md:text-3xl sm:text-2xl leading-tight'>Job Description</h2>
            <p className='text-xl leading-normal'>{responsibilities}</p>
            <br/>
            <br/>
            <h2 className='font-bold lg:text-5xl md:text-3xl sm:text-2xl leading-tight'>Job Responsibilities</h2>
            <p className='text-xl leading-normal'>{responsibilities}</p>
            <ul className='list-decimal pl-5' >
                {
                    responsibilities.map((obj,index)=>{
                        return(
                            <li key={index} style={{color:'#595959',fontSize:20}}>{obj}</li>
                        )
                    })
                }
            </ul>
            <br/>
            <br/>
            <h5 className='font-bold lg:text-3xl md:text-2xl sm:text-xl leading-tight'>Requirements</h5>
            <p className='text-xl leading-normal'>{requirements}</p>
            <ul className='list-decimal pl-5' >
                {
                    requirements.map((obj,index)=>{
                        return(
                            <li key={index} style={{color:'#595959',fontSize:20}}>{obj}</li>
                        )
                    })
                }
            </ul>
            
            </div>

            <button className='text-white bg-gray-400 px-6 py-4 rounded-full w-52 font-bold' style={{background:'#469d2c'}}>
                Apply Now
            </button>

        </div>

        <div className="side_wrapper relative w-full">

            <div className='lg:h-1/3 md:h-2/3 sm: sticky top-0 z-10 w-full rounded-3xl flex flex-col items-center justify-center p-16 gap-5 sm:p-8 lg:p-16 md:p-132' style={{backgroundColor:'#469d2c'}}>
            <PersonPinIcon style={{fontSize:60,color:'#fff'}}/>

            <h4 className='text-white text-3xl text-center font-medium'>Come and join our amazing team</h4>
            <p className='text-center text-gray-100'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto mollitia autem alias, dolorum vel doloremque?</p>

            <button className='text-black bg-gray-400 px-6 py-4 rounded-full' style={{background:'#fbfcf6'}}>
                Join our team <EastIcon/>
            </button>

            </div>


            

        </div>

       </section>

    </div>
  )
}

export default ViewJob