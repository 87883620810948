import React,{useState,useEffect} from "react";
import BannerImg from "../../assets/images/contact_page_title.jpg";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { CheckBadgeIcon,ExclamationCircleIcon } from '@heroicons/react/24/solid'
import { Helmet } from "react-helmet";

export const Contact = () => {
  const [formData,setformData]=useState({
    name:'',
    email:'',
    subject:'',
    phone:'',
    message:'',
  });


  const [errors,setErrors]=useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [statusCode,setStatusCode]=useState()
  const [submitted,setSubmitted]=useState(false);
  const [counter, setCounter] = useState(5);

  const validateField = (name, value) => {
    let errorMessage = '';
    const nameRegex = /^[a-zA-Z\s]{1,50}$/;
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const phoneRegex = /^[0-9]{10}$/;

    switch (name) {
      case 'name':
        if (!nameRegex.test(value)) {
          errorMessage = 'Name must be alphabetic and up to 50 characters long';
        }
        break;
      case 'email':
        if (!emailRegex.test(value)) {
          errorMessage = 'Invalid email format';
        }
        break;
      case 'phone':
        if (!phoneRegex.test(value)) {
          errorMessage = 'Phone number must be 10 digits long';
        }
        break;
      case 'subject':
        if (!value) {
          errorMessage = 'Subject is required';
        }
        break;
      case 'message':
        if (!value) {
          errorMessage = 'Message is required';
        }
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage
    }));
    return errorMessage;
  };

  const updateStatus=(code)=>{
    setStatusCode(code)
  }

  useEffect(()=>{
    if(submitted){
      const interval = setInterval(()=>{
        setCounter((prev)=>prev -1);

      },1000);
      return ()=>clearInterval(interval)
    }
   
  },[submitted])

  useEffect(()=>{
    if(counter === 0){
      window.location.reload()
    }
  },[counter])

  const handleChange = (e)=>{
    const {name,value}=e.target;
    setformData({
      ...formData,
      [name]:value
    });
    validateField(name,value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Final validation before submitting
    const newErrors = {};
    for (const field in formData) {
      const error = validateField(field, formData[field]);
      if (error) {
        newErrors[field] = error;
      }
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const response = await fetch('https://nilaithal.com/contact_form.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      if (response.ok) {
        const data = await response.json();
        console.log('Success:', data);
        setSubmitted(true)
        updateStatus(response.status)
        console.log(response.status)
        console.log(statusCode,'hello world')
      
        setformData({
          name: '',
          email: '',
          subject: '',
          phone: '',
          message: ''
        });
        setErrors({});
      } else {
        setSubmitted(true)
        console.error('Error:', response.statusText);
        updateStatus(response.status)
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    const isValid = Object.values(formData).every((value) => value.trim() !== '');
    setIsFormValid(isValid);
  }, [formData]);


  return (
    <div>
               <Helmet>
        <title>Contact us</title>
        </Helmet>
      <section
        className="grid grid-cols-1 sm:grid-cols-2  h-full px-10"
        style={{ backgroundColor: "#f9fbf5" }}
      >
        <div className="md:py-10 sm:py-5  justify-center flex flex-col h-full">
          <h4 className="sub-text  font-semibold text-xs">CONTACT US</h4>
          <div
            className="flex flex-col justify-between  gap-8"
            style={{ width: "70%" }}
          >
            <h2 className="text-left font-bold  lg:text-5xl">Get In Touch</h2>
            <p className="decription">
              It represents a clean, green source of energy. Solar power is a
              great way to reduce your carbon footprint.
            </p>
          </div>
        </div>
        <div className="justify-center flex flex-col h-full">
          <img src={BannerImg} alt="" style={{ objectFit: "contain" }} />
        </div>
      </section>
      <section className="my-10 px-10">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-[1fr,2fr] gap-10 mt-5 w-full">
          <div className="flex flex-col gap-3 px-4 py-4 rounded-md hover:border-green-500 ">
            <div className="flex flex-col gap-10">
              <h2 className="font-bold text-2xl ">
                {" "}
                Please feel free to reach out to us
              </h2>

              <div className="flex flex-row gap-5 items-center hover:cursor-pointer hover:text-green-500">
                <div
                  className="p-4 rounded-full flex flex-col items-center"
                  style={{ backgroundColor: "#f4f7fc" }}
                >
                  <EmailIcon style={{ color: "#469d2c" }} />
                </div>
                <div>
                  <h5
                    className="font-medium text-xs"
                    style={{ color: "#676e80" }}
                  >
                    Email Address
                  </h5>
                  <h3 className="text-xl">
                    <a href="mailto:milin@india1charger">milin@india1charger</a>
                  </h3>
                </div>
              </div>
              <div className="flex flex-row gap-5 items-center hover:cursor-pointer hover:text-green-500">
                <div
                  className="p-4 rounded-full flex flex-col items-center"
                  style={{ backgroundColor: "#f4f7fc" }}
                >
                  <PhoneIcon style={{ color: "#469d2c" }} />
                </div>
                <div>
                  <h5
                    className="font-medium text-xs"
                    style={{ color: "#676e80" }}
                  >
                    Give Us a Call
                  </h5>
                  <h3 className="text-xl">
                    <a href="tel:+9199629 93939">+91-99629 93939</a>
                  </h3>
                </div>
              </div>
              <div className="flex flex-row gap-5 items-center hover:cursor-pointer hover:text-green-500">
                <div
                  className="p-4 rounded-full flex flex-col items-center"
                  style={{ backgroundColor: "#f4f7fc" }}
                >
                  <LocationOnIcon style={{ color: "#469d2c" }} />
                </div>
                <div>
                  <h5
                    className="font-medium text-xs"
                    style={{ color: "#676e80" }}
                  >
                    Address
                  </h5>
                  <h3 className="text-xl">
                    <a href="#">
                    No 190 - 192, Hameed Complex Anna Salai,Chennai 600006
                    </a>
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div
            className="py-4 px-10 rounded-md  w-full flex flex-col justify-center "
            style={{ backgroundColor: "#f9fbf5" }}
          >
            {
              !submitted ? (
                <form className="w-full flex flex-col gap-10" onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-[1fr,1fr] lg:grid-cols-[1fr,1fr] gap-10 mt-5 w-full items-center justify-center">
              <div className="form-group">
                <label
                  htmlFor="fullname"
                  className="text-xs block mb-2 font-medium text-black "
                >
                  Full Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  placeholder="John Carter"
                  className="bg-white py-4 px-8 rounded-md focus:outline-green-500 leading-tight lg:w-full md:w-full sm:w-full"
                />
                {errors.name && <span className='text-[10px] text-red-700 font-semibold'>{errors.name}</span>}
              </div>
              <div className="form-group">
                <label
                  htmlFor="email"
                  className="text-xs block mb-2 font-medium text-black"
                >
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  placeholder="johncarter@gmail.com"
                  className="bg-white py-4 px-8 rounded-md focus:outline-green-500 leading-tight lg:w-full md:w-full sm:w-full "
                />
                {errors.email && <span className='text-[10px] text-red-700 font-semibold'>{errors.email}</span>}
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-[1fr,1fr] lg:grid-cols-[1fr,1fr] gap-10 mt-5 w-full items-center justify-center">
              <div className="form-group">
                <label
                  htmlFor="phone"
                  className="text-xs block mb-2 font-medium text-black "
                >
                  Phone Number
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  onChange={handleChange}
                  value={formData.phone}
                  required
                  placeholder="(123)-456-7890"
                  className="bg-white py-4 px-8 rounded-md focus:outline-green-500 leading-tight lg:w-full md:w-full sm:w-full"
                />
                {errors.phone && <span className='text-[10px] text-red-700 font-semibold'>{errors.phone}</span>}
              </div>
              <div className="form-group">
                <label
                  htmlFor="subject"
                  className="text-xs block mb-2 font-medium text-black"
                >
                  Subject
                </label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  required
                  onChange={handleChange}
                  value={formData.subject}
                  placeholder="ex. Subject"
                  className="bg-white py-4 px-8 rounded-md focus:outline-green-500 leading-tight lg:w-full md:w-full sm:w-full "
                />
                {errors.subject && <span className='text-[10px] text-red-700 font-semibold'>{errors.subject}</span>}
              </div>
            </div>
            <div className="form-group w-full">
                <label
                  htmlFor="message"
                  className="text-xs block mb-2 font-medium text-black"
                >
                  Your Message
                </label>
                {/* <input
                  type="text"
                  id="email"
                  name="name"
                  required
                  placeholder="johncarter@gmail.com"
                  className="bg-white py-4 px-8 rounded-md focus:outline-green-500 leading-tight lg:w-full md:w-3/5 sm:w-full "
                /> */}
                <textarea id="message" name="message" value={formData.message} onChange={handleChange} rows={4} className="block p-2.5 w-full rounded-md focus:outline-green-500 px-8" placeholder="Your Message..."/>
                {errors.message && <span className='text-[10px] text-red-700 font-semibold'>{errors.message}</span>}
              </div>
              <div>
              <button className={`py-3 px-3 rounded-md text-white font-medium hover:bg-black ${submitted ? 'bg-gray-400':'bg-[#469d2c]'}`}  type='submit' disabled={submitted}>Send Message</button>

              </div>
              
           </form>
           

              ):(
                <>
                {
                  statusCode === 200 ? (
                    <div className=' flex flex-col items-center justify-center gap-5'>
                    <CheckBadgeIcon className="h-16 w-30 stroke-green-500" fill='transparent'  />
                    <h2 className='font-bold text-xl text-green-500'>Thank You For Contacting Us</h2>
                    <h4>This Page will refresh in {counter}</h4>
                  </div>
                  ):(
                    <div className=' flex flex-col items-center justify-center gap-5'>
                    <ExclamationCircleIcon className="h-16 w-30  stroke-red-500" fill='transparent'  />
                    <h2 className='font-bold text-xl text-red-500'>Oops! Something Went Wrong <strong>{statusCode}</strong></h2>
                    <h4>Please try again later</h4>
                    <h4>This Page will refresh in {counter}</h4>
                  </div>
                  )
                }
                </>

              )
            }
            
          </div>

        </div>
      </section>
    </div>
  );
};
