import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import "./style.css";
import CloseIcon from "@mui/icons-material/Close";

const DrawerComponent = ({ menuColor }) => {
  const [open, setOpen] = useState();
  const toggleDrawer = (event) => () => {
    setOpen(event);
  };

  const [screenWidth,setScreenWidth]=useState(window.innerWidth)
  const [isMobile,setIsMobile]=useState(false)

  useEffect(()=>{
    const mediaQuery = window.matchMedia("(max-width:427px)");

    const handleMediaQueryChange = (event)=>{
      setIsMobile(event.matches)
    }

    handleMediaQueryChange(mediaQuery)

    mediaQuery.addEventListener('change',handleMediaQueryChange);

    return ()=>mediaQuery.removeEventListener('change',handleMediaQueryChange);
  },[])
  return (
    <>
      <MenuIcon
        onClick={toggleDrawer(true)}
        className="cursor-pointer"
        style={{ color: `${menuColor}` }}
      />
      <Drawer open={open} onClose={toggleDrawer(false)} anchor="right">
        <Box
          sx={{
            minWidth: isMobile ? screenWidth : 450,
            maxWidth: isMobile ? screenWidth : 450,
            height: "100%",
            position: "relative",
          }}
          className="py-5"
        >
          <div
            className="close-menu cursor-pointer"
            onClick={toggleDrawer(false)}
          >
            <span style={{ color: "red" }}>
              Close <CloseIcon />
            </span>
          </div>
          <div className="w-full h-full  grid place-items-center">
            <ul className="nav-link">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/about">About us</a>
              </li>
              <li>
                <a href="/services">Services</a>
              </li>
              <li>
                <a href="/products">Products</a>
              </li>
              <li>
                <a href="/partners">Partners</a>
              </li>
              <li>
                <a href="/careers">Careers</a>
              </li>
              <li>
                <a href="/contact">Contact</a>
              </li>
              {/* <Link to={"/"}><span>Home</span></Link>
      <Link to={"/about"}><span>About us</span></Link>
      <Link to={"/services"}><span>Services</span></Link>
      <Link to={"/blogs"}><span>Blogs</span></Link>
      <Link to={"/partners"}><span>Partners</span></Link>
      <Link to={"/carrers"}><span>Careers</span></Link>
      <Link to={"/contact"}><span>Contact</span></Link> */}
            </ul>
          </div>
        </Box>
      </Drawer>
    </>
  );
};

export default DrawerComponent;
