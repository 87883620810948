import React, { useEffect, useState } from 'react'
import ChargerIMg1 from '../../assets/images/AC1.png'
import ChargerIMg2 from '../../assets/images/AC2.png'
import { Link } from 'react-router-dom'

import { CircularProgress } from '@mui/material'
import axios from 'axios';
import { Helmet } from 'react-helmet'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'


const Products = () => {

    // const data =[
    //     {
    //       id: "ac1",
    //       name: "AC 3.3 kW charger with 3 to 5 universal sockets per one Smart Control Unit",
    //       voltage: "3.3 KW",
    //       price: 78000,
    //       currency: "INR",
    //       description: "A reliable AC charger for home use.",
    //       brand: "ChargerBrand",
    //       category: "AC",
    //       stock: 100,
    //       main_image: "https://ev.ideassion.in/wp-content/uploads/2024/04/image-removebg-preview-5-176x300.png",
    //       images: [
    //         ChargerIMg1,
    //         ChargerIMg2,
    //         "https://ev.ideassion.in/wp-content/uploads/2024/04/image-removebg-preview-4-198x300.png"
    //       ]
    //     },
    //     {
    //       id: "ac2",
    //       name: "AC 15 kW charger with Dual Type 2 connector (7.4kw each) / (IEC 62196)",
    //       voltage: "15KW",
    //       price: 92450,
    //       currency: "INR",
    //       description: "A powerful AC charger for international use.",
    //       brand: "PowerPlus",
    //       category: "AC",
    //       stock: 50,
    //       main_image: "https://ev.ideassion.in/wp-content/uploads/2024/04/image-removebg-preview-8-134x300.png",
    //       images: [
    //         "https://ev.ideassion.in/wp-content/uploads/2024/04/image-removebg-preview-8-134x300.png",
    //         ChargerIMg1,
    //         ChargerIMg2,
           
    //       ]
    //     },
    //     {
    //       id: "dc1",
    //       name: "30 kW DC (Fast) charger with single connector (CCS2)",
    //       voltage: "30KW",
    //       price: 57700,
    //       currency: "INR",
    //       description: "A compact DC charger for automotive use.",
    //       brand: "AutoCharge",
    //       category: "DC",
    //       stock: 75,
    //       main_image: "https://ev.ideassion.in/wp-content/uploads/2024/04/image-removebg-preview-4-198x300.png",
    //       images: [
    //         ChargerIMg1,
    //         ChargerIMg2,
    //         "https://example.com/images/dc1_side.jpg",
    //         "https://example.com/images/dc1_back.jpg"
    //       ]
    //     },
    //     {
    //       id: "dc2",
    //       name: "60 kW DC (Fast) charger with single connector (CCS2)",
    //       voltage: "60KW",
    //       price: 85000,
    //       currency: "INR",
    //       description: "A high-power DC charger for trucks and heavy vehicles.",
    //       brand: "TruckPower",
    //       category: "DC",
    //       stock: 30,
    //       main_image: "https://ev.ideassion.in/wp-content/uploads/2024/04/image-removebg-preview-4-198x300.png",
    //       images: [
    //         ChargerIMg1,
    //         ChargerIMg2,
    //         "https://example.com/images/dc2_front.jpg",
    //         "https://example.com/images/dc2_side.jpg",
    //         "https://example.com/images/dc2_back.jpg"
    //       ]
    //     },
    //     {
    //       id: "pc1",
    //       name: "AC 7.4 kW charger with Type 2 connector with (IEC 62196) and 3.3 universal socket",
    //       voltage: "7.4KW",
    //       price: 78750,
    //       currency: "INR",
    //       description: "A handy portable charger for mobile devices.",
    //       brand: "TravelCharge",
    //       category: "Portable",
    //       stock: 150,
    //       main_image: "https://ev.ideassion.in/wp-content/uploads/2024/04/image-removebg-preview-9.png",
    //       images: [
    //         ChargerIMg1,
    //         ChargerIMg2,
    //         "https://ev.ideassion.in/wp-content/uploads/2024/04/image-removebg-preview-9.png",
    //       ]
    //     },
    //     {
    //       id: "pc2",
    //       name: "AC 22 kW charger with Type 2 connector (IEC 62196)",
    //       voltage: "9V",
    //       price: 68250,
    //       currency: "INR",
    //       description: "A fast-charging portable charger for smartphones and tablets.",
    //       brand: "FastCharge",
    //       category: "Portable",
    //       stock: 100,
    //       main_image: "https://ev.ideassion.in/wp-content/uploads/2024/04/image-removebg-preview-10.png",
    //       images: [
    //         ChargerIMg1,
    //         ChargerIMg2,
    //         "https://ev.ideassion.in/wp-content/uploads/2024/04/image-removebg-preview-10.png"
    //       ]
    //     },
    //   ]

    const url = 'https://backend.nilaithal.com';

      const [data,setData]=useState('');
      const [error,setError]=useState(null)

      useEffect(()=>{

    fetchData();

      },[])

      const fetchData = async ()=>{
        try{
          const options = {
            method: "GET",
            url: "https://backend.nilaithal.com/api/products?populate=*",
          };
          const response = await axios.request(options);
          // console.log(response.data);
          setData(response.data.data);
          setLoading(false)
  
          }
          catch(error){
            console.log("Error ",error)
            
            setError("Network Error, Please Try Again Later.")
            // alert(error);
            setLoading(false)
  
          }

      }
      const [loading,setLoading]=useState(true);

      const [hoveredImage,setHoveredImage]=useState(null);


      if(error){
        return <><div className='min-h-[50vh] flex flex-col items-center justify-center gap-2'>
          <ExclamationTriangleIcon className='h-16 w-16  stroke-gray-300'/>
          <h4 className='text-center text-xl font-semibold text-gray-500'>{error}</h4>
          </div></>
      }
      
  return (

    <>
             <Helmet>
        <title>Products</title>
        </Helmet>
    {/* <CircularProgress/> */}
    <section className='h-1/2 w-full bg-[#f9f9f9] px-5 xl:px-32 md:px-16 py-10 xl:py-32 md:py-16'>
    <div className='flex flex-col gap-5'>
    <h2 className='font-bold xl:text-[56px] text-4xl'>
        Shop
    </h2>
    <p className='text-xl'>
    It represents a clean, green source of energy. Solar power is a great way to reduce your carbon footprint.
    </p>

    </div>

    {
      loading ? (<>
          <div className="min-h-screen items-center flex flex-col gap-2 justify-center">
            <CircularProgress variant="solid" color="success" />
            <p className="font-bold text-black text-center z-10 animate-pulse">
              Loading
            </p>
          </div>
      </>):( <div className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 md:grid-cols-2  place-items-center gap-5 my-10'>
        {
            data.map((item,index)=>(
              // <p>{item.attributes.name}</p> 
              
                <Link to={`/products/${item.name.replace(/\s+/g, '-').toLowerCase()}/`} state={{productId:`${item.id}`}} key={item.id} className='border-2 relative rounded-lg  group bg-[#f1f1f1] hover:border-[#5d9b3e] h-full xl:w-[400px] lg:w-[400px] md:w-[300px] w-full'>
                    <div className=' flex flex-row items-center justify-center'>
                    {/* <div className="relative h-[200px] w-[300px] py-5 " onMouseEnter={()=>setHoveredImage(item.images.data.attributes.formats.thumbnail.url)} onMouseLeave={()=>setHoveredImage(null)}> */}
                    <div className="relative h-[200px] w-[300px] py-5 ">
                      {/* <img src={`${url}${hoveredImage === item.id ? item.image.formats.thumbail.url : item.images.data.attributes.formats.thumbnail.url}`} alt={item.name} className=" h-full self-stretch flex-shrink-0 object-contain w-full transition transform duration-300 ease-in-out   group-hover:scale-110" /> */}
                      <img src={`${url}${item.image[0].formats.thumbnail.url}`} alt={item.image[0].name} className=" h-full self-stretch flex-shrink-0 object-contain w-full transition transform duration-300 ease-in-out   group-hover:scale-110" />
                    </div>
                    </div>
                    
                    <div className='flex flex-col relative  gap-5  px-5 py-2 bg-white rounded-b-lg h-[250px] bg'>
                    <h4 className='font-bold text-xl  line-clamp-2 overflow-hidden text-wrap group-hover:text-[#5d9b3e] text-ellipsis'>{item.name}</h4>
                    <h6 className='font-bold'>Volatage: 3.3V</h6>
                    

                    <p className='font-semibold'>₹ {item.price.toLocaleString()}</p>
                    <div className='flex flex-row gap-5 absolute bottom-10 '>
                        <button className='bg-[#6b994b] text-white px-5 py-2 rounded-lg hover:bg-black'>Learn More</button>
                        <button className='border px-5 rounded-lg hover:text-[#6b994b]'>Buy Now</button>
                    </div>
                  
    
                    </div>
                  
                    
                </Link>
            ))
        }
    
    
        </div>)
    }

   
   


    </section>
    </>
  )
}

export default Products