import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import Slider1 from "../../../assets/images/bg1.jpg";
import Slider2 from "../../../assets/images/bg2.jpg";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import XIcon from "@mui/icons-material/X";
import { animate, motion, useAnimation } from "framer-motion";
// import { useInView } from "react-intersection-observer";

const SliderComponent = () => {
  const sliderRef = useRef(null);
  const [progress, setProgress] = useState();
  let currentIndex = 0;
  let interval;

  const nextSlide = () => {
    const slider = sliderRef.current;
    const slides = slider.querySelectorAll(".slide");
    const totalSlides = slides.length;
    slides[currentIndex].classList.remove("active");
    currentIndex = (currentIndex + 1) % totalSlides;
    slides[currentIndex].classList.add("active");
  };

  const prevSlide = () => {
    const slider = sliderRef.current;
    const slides = slider.querySelectorAll(".slide");
    const totalSlides = slides.length;
    slides[currentIndex].classList.remove("active");
    currentIndex = (currentIndex - 1 + totalSlides) % totalSlides;
    slides[currentIndex].classList.add("active");
  };

  useEffect(() => {
    const slider = sliderRef.current;
    const slides = slider.querySelectorAll(".slide");
    const totalSlides = slides.length;

    const updateProgress = () => {
      const progress = (currentIndex / (totalSlides - 1)) * 100;
      setProgress(progress);
    };

    const startAutoPlay = () => {
      interval = setInterval(nextSlide, 5000); // Change slide every 5 seconds
    };

    startAutoPlay();
    updateProgress();
  

    return () => clearInterval(interval);
  }, [currentIndex]);

  const ZoomInAnimationProp = {
    initial: { opacity: 0, scale:0.5 },
    animate: {
      opacity: 1,
      scale:1
     
    },
  };

  const control = useAnimation();



  const gradientTextStyles = {
    background:
      "linear-gradient(90deg, #F58529 0%, #FEDA77 25%, #DD2A7B 50%, #8134AF 75%, #515BD4 100%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    color: "transparent",
    fontSize: "72px",
    fontWeight: "bold",
  };

  return (
    <div className="slider" ref={sliderRef}>
      <div
        className="slide active"
        style={{
          background: `linear-gradient(rgba(0, 0, 0, 0.651),rgba(0, 0, 0, 0.463)),url(${Slider1}) no-repeat`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/* <img src={Slider1} alt="Slide 1" /> */}
        <div className="content w-4/5">
          <motion.h2
            // variants={ZoomInAnimationProp}
            initial={{opacity:0,scale:0.5}}
            whileInView='visible'
          
            animate={{opacity:1,scale:1}}
            transition={{
              duration:0.8,
              delay:0.5,
              ease:[0,0.71,0.2,1.01]
            }}
            className="slider-titleText lg:text-7xl md:text-4xl sm:text-2xl animate-fadeinup"
          >
            Join the Movement Towards a Greener Tomorrow
          </motion.h2>
          <p className="text-white leading-tight" style={{ fontSize: 18 }}>
            where innovation meets sustainability. We are a leading provider of
            renewable energy solutions dedicated to powering a greener future.
            From solar to wind, we harness the power of nature to create clean,
            reliable energy for homes, businesses, and communities worldwide.{" "}
          </p>
        </div>
      </div>
      <div
        className="slide"
        style={{
          background: `linear-gradient(rgba(0, 0, 0, 0.651),rgba(0, 0, 0, 0.463)),url(${Slider2}) no-repeat`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="content w-4/5">
          <motion.h2
            initial={{opacity:0,scale:0.5}}
          
            animate={{opacity:1,scale:1}}
            transition={{
              duration:0.8,
              delay:0.5,
              ease:[0,0.71,0.2,1.01]
            }}
            whileInView='visible'
            className="slider-titleText lg:text-7xl md:text-4xl sm:text-2xl"
          >
            Lighting the Path to Sustainable Energy Solutions
          </motion.h2>
          <p className="text-white leading-tight" style={{ fontSize: 18 }}>
            we're dedicated to harnessing the power of renewable energy to
            create a brighter, cleaner tomorrow. With a focus on innovation,
            sustainability, and excellence, we strive to lead the way towards a
            greener future for generations to come.
          </p>
          <p className="text-white leading-tight" style={{ fontSize: 18 }}>
            Our commitment to renewable energy solutions is rooted in our
            passion for environmental stewardship and social responsibility.{" "}
          </p>
        </div>
      </div>
      <div className="navcontrolContainer">
        <div className="inner px-4 md:w-2/4 sm:w-11/12">
          <div className="control-container">
            <div className="control-btn hover:bg-slate-300" onClick={prevSlide}>
              <ArrowUpwardIcon />
            </div>
            <div className="control-btn hover:bg-slate-300" onClick={nextSlide}>
              <ArrowDownwardIcon />
            </div>
          </div>
          <ul className="social-links">
            <li>
              <a
                href="https://www.facebook.com/profile.php?id=61557640918659"
                target="_blank"
              >
                <FacebookRoundedIcon style={{ color: "#2c64f6" }} />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/nilaithalofficial/?hl=en"
                target="_blank"
              >
                <InstagramIcon style={{ color: "#F58529" }} />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/nilaithal/"
                target="_blank"
              >
                <LinkedInIcon style={{ color: "#2d64bc" }} />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/Nilaithal_" target="_blank">
                <XIcon style={{ color: "#000000" }} />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SliderComponent;
