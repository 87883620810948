import React, { useState, useEffect } from 'react';

const Counter = ({ endValue,sign }) => {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          let start = 0;
          const step = Math.ceil(endValue / 100); // Divide the end value by 100 to get 100 steps
          const interval = setInterval(() => {
            start += step;
            setCounter(start);
            if (start >= endValue) {
              clearInterval(interval);
            }
          }, 20); // Adjust the interval duration (e.g., increase to slow down)
          observer.unobserve(entry.target);
        }
      });
    }, { threshold: 0.5 });

    observer.observe(document.getElementById('counter-section'));

    // Cleanup function
    return () => observer.disconnect();
  }, [endValue]);

  return (
    <div id="counter-section">
      {/* <h2>Counter Section</h2> */}
      <p style={{color:'#469d2c',fontSize:45,}} className='font-semibold'>{counter.toLocaleString()}{sign}</p>
    </div>
  );
};

export default Counter;
