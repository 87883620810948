import React from "react";
import Logo from "../../assets/images/logo.png";
import WifiCalling3RoundedIcon from "@mui/icons-material/WifiCalling3Rounded";
import MailOutlineRoundedIcon from "@mui/icons-material/MailOutlineRounded";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import "./style.css";
import { Link } from "react-router-dom";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
export const Footer = () => {

  const data =  [
      {
        "title": "Green Energy: A Sustainable Future",
        "author": "John Doe",
        "date": "2024-04-09",
        "content": {
          "introduction": "Green energy is the future.",
          "types": {
            "solar": "Solar energy is the most abundant energy source on Earth.",
            "wind": "Wind energy is a clean and sustainable source of electricity.",
            "hydro": "Hydroelectric power is the most widely used form of renewable energy."
          },
          "conclusion": "Green energy is the key to a sustainable future."
        }
      },
      {
        "title": "The Benefits of Solar Energy",
        "author": "Jane Smith",
        "date": "2024-04-10",
        "content": {
          "introduction": "Solar energy is a clean and renewable source of electricity.",
          "benefits": {
            "environmental": "Solar energy reduces greenhouse gas emissions and air pollution.",
            "economic": "Solar energy creates jobs and saves money on energy bills.",
            "social": "Solar energy promotes energy independence and enhances quality of life."
          },
          "conclusion": "Solar energy is a vital component of a sustainable future."
        }
      },
      {
        "title": "The Power of Wind Energy",
        "author": "John Doe",
        "date": "2024-04-11",
        "content": {
          "introduction": "Wind energy is a clean and renewable source of electricity.",
          "benefits": {
            "environmental": "Wind energy reduces greenhouse gas emissions and air pollution.",
            "economic": "Wind energy creates jobs and saves money on energy bills.",
            "social": "Wind energy promotes energy independence and enhances quality of life."
          },
          "conclusion": "Wind energy is a vital component of a sustainable future."
        }
      }
    ]
 
  return (
    <div style={{ backgroundColor: "#1b1b1b" }}>
      <div className="grid grid-cols-1 md:grid-cols-[1fr,1fr] lg:grid-cols-[1fr,1fr,1fr,1fr] pb-10" >
        <div className=" pt-5 px-5 flex flex-col md:items-center sm:items-start items-center justify-center">
          <div className="logo">
            <img src={Logo} />
          </div>
        </div>
        <div className=" pt-5 px-5 flex flex-col md:items-start sm:items-start items-start justify-center">
          <div className="icon-box flex flex-row items-center gap-4 ">
            <WifiCalling3RoundedIcon
              color="#469d2c"
              style={{ color: "#469d2c", fontSize: 35 }}
            />
            <div className="box-content">
              <a href="tel:+9199629 93939" className="text-white font-bold text-xl">
              +91 99629 93939
              </a>
              <p className="text-white">Call Us For Fast Support</p>
            </div>
          </div>
        </div>
        <div className=" pt-5 px-5 flex flex-col md:items-start sm:items-start items-start justify-center">
          <div className="icon-box flex flex-row items-center gap-4">
            <MailOutlineRoundedIcon
              color="#469d2c"
              style={{ color: "#469d2c", fontSize: 35 }}
            />
            <div className="box-content">
              <a href="mailto:milin@india1charger" className="text-white font-bold text-xl">
              milin@india1charger
              </a>
              <p className="text-white">Email Us for Query</p>
            </div>
          </div>
        </div>
        <div className=" pt-5 px-5 flex flex-col md:items-start sm:items-start items-start justify-center">
          <div className="icon-box flex flex-row items-center gap-4">
            <MapOutlinedIcon
              color="#469d2c"
              style={{ color: "#469d2c", fontSize: 35 }}
            />
            <div className="box-content">
              <a href="#" className="text-white font-bold text-xl">
              No 190 - 192, Hameed Complex Anna Salai,
              </a>
              <p className="text-white">Chennai 600006</p>
            </div>
          </div>
        </div>
      </div>
      <div style={{display:'grid',placeItems:'center'}}>
      <hr style={{width:'70%', textAlign:'center',}}/>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-[1fr,1fr] md:grid-cols-[1fr,1fr,1fr,1fr] pb-10 px-10" >
        <div className=" pt-5 px-5 flex flex-col md:items-center sm:items-start">
          <div className="flex flex-col gap-4">
          <h4 className="text-white font-bold text-xl text-left">Enter Your email to get the latest news</h4>
          {/* <p className="text-white">
          Creating an awesome website with this Theme is easy than you can imagine. Get Solarta and discover home services today.
          </p> */}
          <div className="mt-6 flex max-w-md gap-x-4">
            
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                placeholder="Enter your email"
              />
              <button
                type="submit"
                className="flex-none rounded-md  px-3.5 py-2.5 text-sm font-semibold bg-green-600 text-white shadow-sm hover:bg-white hover:text-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                // style={{backgroundColor:'#469d2c',}}
              >
                Subscribe
              </button>
            </div>
          </div>
          <div className="w-full my-5">
            <h4>DUNS Number <span>64-496-0764</span></h4>
          <iframe id="Iframe1" src="
https://dunsregistered.dnb.com/SealAuthentication.aspx?Cid=1"
width="114px" height="97px" frameborder="0" scrolling="no" allowtransparency="true"></iframe>
<script language="JavaScript" src="
https://dunsregistered.dnb.com"
type="text/javascript"></script>
          </div>

        </div>
        <div className=" pt-5 px-5 flex flex-col  md:items-center sm:items-start">
          <div className="flex flex-col gap-4">
          <h4 className="text-white font-bold text-xl text-left">Useful Links</h4>
         <ul className="footer-links flex flex-col gap-3">
         <Link to={"/"}><li style={{color:'#fff'}}>Home</li></Link>
        <Link to={"/about"}><li style={{color:'#fff'}}>About us</li></Link>
        <Link to={"/Services"}><li style={{color:'#fff'}}>Services</li></Link>
        <Link to={"/blogs"}><li style={{color:'#fff'}}>Blogs</li></Link>
        <Link to={"/partners"}><li style={{color:'#fff'}}>Partners</li></Link>
        <Link to={"/careers"}><li style={{color:'#fff'}}>Careers</li></Link>
        <Link to={"/contact"}><li style={{color:'#fff'}}>Contact</li></Link>

         </ul>
          </div>
        </div>
        {/* <div className=" pt-5 px-5 flex flex-col  md:items-center sm:items-start">
          <div className="flex flex-col gap-4">
          <h4 className="text-white font-bold text-xl text-left">Company</h4>
         <ul className="footer-links flex flex-col gap-3">
         <Link to={"/terms-and-conditions"}><li style={{color:'#fff'}}>TermsandConditions</li></Link>
        <Link to={"/refund-policy"}><li style={{color:'#fff'}}>Refund Policy</li></Link>
        <Link to={"/privacy-policy"}><li style={{color:'#fff'}}>Privacy Policy</li></Link>


         </ul>
          </div>
        </div> */}
        <div className=" pt-5 px-5 flex flex-col md:items-center sm:items-start">
          <div className="flex flex-col gap-4">
          <h4 className="text-white font-bold text-xl text-left">Follow Us</h4>
         <ul className="footer-links flex flex-col gap-3">
         <li style={{color:'#fff'}}><a href="https://www.facebook.com/profile.php?id=61557640918659" target="_blank">Facebook</a></li>
         <li style={{color:'#fff'}}><a href="https://www.instagram.com/nilaithalofficial/?hl=en" target="_blank">Instagram</a></li>
         <li style={{color:'#fff'}}><a href="https://www.linkedin.com/company/nilaithal/" target="_blank">LinkedIn</a></li>
         <li style={{color:'#fff'}}><a href="https://twitter.com/Nilaithal_" target="_blank">Twitter</a></li>
         <li style={{color:'#fff'}}><a href="https://www.youtube.com/channel/UCHmnNoXll5Nw4EFMLRc9Dbw" target="_blank">Youtube</a></li>


         </ul>
          </div>
        </div>
        <div className=" pt-5 px-5 flex flex-col md:items-center sm:items-start">
          <div className="flex flex-col gap-4">
          {/* <h4 className="text-white font-bold text-xl text-left">Latest Post</h4> */}
         <ul className="footer-links flex flex-col gap-7">
          {/* {
            data.map((obj,index)=>
              (
                // <h3>{obj.title}</h3>
                <div className="flex flex-col gap-2" key={index}>
                  <li style={{color:'#fff'}}><a href="" className="hover:text-green-600">{obj.title}</a>
                  <p className="text-xs text-gray-400"><CalendarMonthIcon/>&nbsp;<span>{obj.date}</span></p>
                  
                  
                  </li>
                  <hr />
                </div>

              )
              
            )
          } */}
          <Link to={"/terms-and-conditions"}><li style={{color:'#fff'}}>Terms and Conditions</li></Link>
        <Link to={"/refund-policy"}><li style={{color:'#fff'}}>Refund Policy</li></Link>
        <Link to={"/privacy-policy"}><li style={{color:'#fff'}}>Privacy Policy</li></Link>



         </ul>
          </div>
        </div>
      
      
      </div>
      <div style={{display:'grid',placeItems:'center'}}>
      <hr style={{width:'70%', textAlign:'center',}}/>
      </div>
      <div className="flex flex-row px-10  items-center justify-center h-10 text-white ">
        <p className="text-xs text-white text-center">© 2024 Nilaithal | Powered By Ideassion</p>
        {/* <div className="text-xs gap-3 flex flex-row cursor-pointer ">
        <Link to={"/terms-and-conditions"}><span>Terms and Conditions</span></Link>
          
          <span>|</span>
          <Link to={"/refund-policy"}><span>Refund Policy</span></Link>
          <span>|</span>refund-policy
          <Link to={"/privacy-policy"}><span>Privacy Policy</span></Link>
          
        </div> */}
        </div>      
    </div>
  );
};
