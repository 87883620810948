import './App.css';
import { Home } from './Screens/Home';
import { Aboutus } from './Screens/About us';
import { Services } from './Screens/Services';
import { Blogs } from './Screens/Blogs';
import { Careers } from './Screens/Careers';
import { Partners } from './Screens/Partners';
import { Contact } from './Screens/Contact Us';
import { HomePageNavbar, Navbar } from './Components/Navbar';
import { Routes, Route, Outlet, Link,useLocation } from "react-router-dom";
import { DefaultNavbar } from './Components/DefaultNavbar';
import Projects from './Screens/Home/ProjectPage';
import { Footer } from './Components/Footer';
import ScrollToTop from './Scroll'
import ScrollBackToTop from './Components/BacktoTop';
import WindTurbines from './Screens/Home/ServiceDetail/Wind';
import ServiceDetails from './Screens/Home/ServiceDetail/Wind';
import ReadBlog from './Screens/Blogs/BlogDetail';
import ViewJob from './Screens/Careers/ViewJob';
import PageNotFound from './Screens/Not Found';
import TermsandConditions from './Screens/T&C';
import PrivacyPolicies from './Screens/PrivacyPolicy';
import RefundPolicy from './Screens/RefundPolicy';
import Products from './Screens/Products';
import ProductDetails from './Components/ProductDetails';
import BillingPage from './Screens/BillingScreen';
import PaymentConfirmation from './Screens/PaymentConfirmation';

const App = ()=>{
  // const router = createBrowserRouter([
  //   {
      
  //   },
  //   {
  //     path:"/",
  //     element:<Home/>
  //   },
  //   {
  //     path:"/about-us",
  //     element:<Aboutus/>
  //   },
  //   {
  //     path:"/services",
  //     element:<Services/>
  //   },
  //   {
  //     path:"/blogs",
  //     element:<Blogs/>
  //   },
  //   {
  //     path:"/carrers",
  //     element:<Careers/>
  //   },
  //   {
  //     path:"/partners",
  //     element:<Partners/>
  //   },
  //   {
  //     path:"/contact-us",
  //     element:<Contact/>
  //   },
  // ])
  const currentPath = window.location.pathname;
  let NavbarComponent;
  if(currentPath === '/'){
    NavbarComponent = <HomePageNavbar/>;
  }
  else{
    NavbarComponent = <DefaultNavbar/>
  }
  const location = useLocation()

  const Homepage = location.pathname === '/'
  return(

    <div>
    {
      Homepage ? <HomePageNavbar/> : <DefaultNavbar/>
    }
    {/* <RouterProvider router={router} /> */}
    <ScrollToTop/>
    <Routes>
      
      <Route path='/' element={<Home/>}/>
      <Route path='/about' element={<Aboutus/>}/>
      <Route path='/services' element={<Services/>}/>
      <Route path='/blogs' element={<Blogs/>}/>
      <Route path='/products' element={<Products/>}/>
      <Route path='/products/:productname/' element={<ProductDetails/>}/>
      <Route path='/careers' element={<Careers/>}/>
      <Route path='/partners' element={<Partners/>}/>
      <Route path='/contact' element={<Contact/>}/>
      <Route path='/projects' element={<Projects/>}/>
      <Route path='/service-details/' element={<ServiceDetails/>}/>
      <Route path='/blog-details/' element={<ReadBlog/>}/>
      <Route path='/job-details/' element={<ViewJob/>}/>
      <Route path='/terms-and-conditions/' element={<TermsandConditions/>}/>
      <Route path='/privacy-policy/' element={<PrivacyPolicies/>}/>
      <Route path='/refund-policy/' element={<RefundPolicy/>}/>
      <Route path='/checkout/' element={<BillingPage/>}/>
      <Route path='/payment-status/:id' element={<PaymentConfirmation/>}/>
      <Route path='*' element={<PageNotFound/>}/>
    </Routes>
    <ScrollBackToTop/>
    <Footer/>
    </div>


  )
}

export default App;
