import React, { useEffect, useRef, useState } from 'react'
import './style.css'
const ImageCarousel = ({images}) => {
    const [currentImage,setCurrentImage]=useState(0)
    const intervalId = useRef(null)


    const handlePrevClick = ()=>{
        setCurrentImage((previmg)=>(previmg - 1 +images.length) % images.length)
    }

    const handleMouseEnter = ()=>{
        clearInterval(intervalId.current)
    }

    const handleMouseLeave = ()=>{
        intervalId.current = setInterval(()=>{
            setCurrentImage((previmg)=>(previmg + 1) % images.length)
        },5000)
    }

    useEffect(()=>{
        intervalId.current = setInterval(() => {
            setCurrentImage((previmg)=>(previmg + 1) % images.length)
            
        }, 5000);

        return ()=>{
            clearInterval(intervalId.current)
        }
    },[])

    const handleNextClick = ()=>{
        setCurrentImage((previmg)=>(previmg + 1 ) % images.length)
    }
  return (
    <div className='relative fade-in w-full' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{height:500}}>
        <button className='absolute top-1/2 transfrom -translate-y-1/2 left-4 z-10 bg-gray-200 p-2 rounded-full ' onClick={handlePrevClick}>
            &lt;
        </button>
        <img src={images[currentImage]} alt="" className="object-cover fade-in rounded-xl " style={{height:500}} />
     
        <button className='absolute top-1/2 transfrom -translate-y-1/2 right-4 z-10 bg-gray-200 p-2 rounded-full' onClick={handleNextClick}>
            &gt;
        </button>

        <div className="
        flex justify-center mt-4">
            {
                images.map((images,index)=>(
                    <button key={index} className={`w-2 h-2 rounded-full  m-1`} style={currentImage === index ? {backgroundColor:'#469d2c'}:{backgroundColor:'grey'}} onClick={()=>setCurrentImage(index)}/>
                ))
            }
        </div>

    </div>
  )
}

export default ImageCarousel