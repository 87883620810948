import React from 'react'
import BannerImg from "../../assets/images/services_page_title.jpg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Helmet } from 'react-helmet';

export const Services = () => {
  const serviceData = [
    {
      "id":1,
      "title":"EV Charging Solutions",
      "content":"Charge up your electric vehicle with ease using Nilaithal's EV charging solutions. Our electric vehicle chargers are designed for convenience, reliability, and efficiency, allowing you to charge your vehicle at home or on the go. With our charging solutions, you can contribute to a greener future while enjoying the benefits of electric transportation. "
      },

      {
      "id":2,
      "title":"Wind Energy Solutions",
      "content":"Harness the natural power of the wind with Nilaithal's cutting-edge wind energy solutions. Our wind turbines are designed to capture the kinetic energy of the wind and convert it into electricity. From small-scale residential turbines to large-scale commercial installations, we offer a range of wind energy solutions tailored to your needs."
      
      },
     
      {
        "id":3,
        "title":"Solar Energy Solutions",
  
        "content":"Unlock the potential of solar energy with Nilaithal's advanced solar panel solutions. Our solar panels are engineered to capture sunlight and convert it into clean, renewable electricity. Whether you're looking to power your home, business, or community, our solar energy solutions are reliable, efficient, and environmentally friendly."
        },
      // {
      // "id":4,
      // "title":"Biomass Energy Solutions",
      // "content":"Transform waste into energy with our biomass energy solutions, including biomass power plants, biofuel production, and biomass consulting services. "
      // }
  ]



  const serviceFeatureData = [
    {
      "id": 1,
      "title": "Efficiency",
      "content": "Our renewable energy solutions are designed for maximum efficiency, ensuring optimal performance and energy savings."
    },
    {
      "id": 2,
      "title": "Reliability",
      "content": "Nilaithal's products are built to last, with robust construction and advanced technology for reliable operation in any environment."
    },
    {
      "id": 3,
      "title": "Environmental Impact",
      "content": "By harnessing renewable resources such as wind and solar energy, our solutions help reduce carbon emissions and combat climate change."
    },
    {
      "id": 4,
      "title": "Scalability",
      "content": "Whether you're looking for a small-scale residential solution or a large-scale commercial installation, Nilaithal's products can be tailored to meet your specific needs."
    },
    {
      "id": 5,
      "title": "Cost Savings",
      "content": "With lower operating costs and potential incentives, our renewable energy solutions offer long-term cost savings for homeowners, businesses, and communities."
    }
  ];

  
  return (
    <div>
               <Helmet>
        <title>Services</title>
        </Helmet>
        <section
        className="grid grid-cols-1 sm:grid-cols-2  h-full px-10"
        style={{ backgroundColor: "#f9fbf5" }}
      >
        <div className="md:py-10 sm:py-5  justify-center flex flex-col h-full px-5">
          <h4 className="sub-text  font-semibold text-xs">Services</h4>
          <div
            className="flex flex-col justify-between  gap-8"
            
          >
            <h2 className="text-left font-bold  lg:text-5xl">
            Empowering Tomorrow, Sustainably Today
            </h2>
            <p className="decription text-justify">
            Nilaithal leads the charge towards a greener future by harnessing renewable energy sources. Our innovative solutions not only provide clean and efficient energy but also empower individuals, businesses, and communities to make a positive impact on the environment. Join us in shaping a sustainable tomorrow, starting today.
            </p>
          </div>
        </div>
        <div className="justify-center flex flex-col h-full">
          <img src={BannerImg} alt="" style={{ objectFit: "contain" }} />
        </div>
      </section>
      <section className="my-10 px-10 flex flex-col justify-center">
        <div className="md:px-10 justify-center flex flex-col h-full items-center ">
          <h4 className="sub-text  font-semibold text-xs">Our Services</h4>
          <div className="flex flex-col justify-between  gap-8 lg:w-3/6 md:w-4/5 sm:w-full">
            <h2 className="md:text-4xl text-center font-bold sm:text-4xl">
              Reasons Why Choose Us
            </h2>
            <p className="decription text-center">
              We believe we can make a difference to this world, to this very
              earth on which we live. We have been destroying the forests.
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4 mt-5">
          {
            serviceData.map((obj)=>
              (
                <div className="p-4 flex flex-col gap-4 shadow-md rounded-md hover:border-green-500 hover:border-2 hover:textwhite transition-all ease-in border-2" key={obj.id}>
            <div className="flex flex-col items-start hover:text-white">
              <CheckCircleIcon
                className="text-center"
                style={{ textAlign: "center", color: "#469d2c", fontSize: 40 }}
              />
            </div>
            <h5 className="font-bold text-xl">{obj.title}</h5>
            <p className="">
              {obj.content}
            </p>
          </div>
              )
            )
          }
        </div>
      </section>

      <section className="my-10 px-10 flex flex-col justify-center">
        <div className="md:px-10 justify-center flex flex-col h-full items-center ">
          {/* <h4 className="sub-text  font-semibold text-xs">Our Services</h4> */}
          <div className="flex flex-col justify-between  gap-8 lg:w-3/6 md:w-4/5 sm:w-full">
            <h2 className="md:text-4xl text-center font-bold sm:text-3xl">
            Service Features
            </h2>
            <p className="decription text-center">
            Committed to sustainability and efficiency, NILAITHAL provides energy efficiency solutions to optimize energy consumption and reduce carbon footprint, including 
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4 mt-5">
          {
            serviceFeatureData.map((obj)=>
              (
                <div className="p-4 flex flex-col gap-4 shadow-md rounded-md hover:border-green-500 hover:border-2 hover:textwhite transition-all ease-in border-2" key={obj.id}>
            <div className="flex flex-col items-start hover:text-white">
              <CheckCircleIcon
                className="text-center"
                style={{ textAlign: "center", color: "#469d2c", fontSize: 40 }}
              />
            </div>
            <h5 className="font-bold text-xl">{obj.title}</h5>
            <p >
              {obj.content}
            </p>
          </div>
              )
            )
          }
        </div>
      </section>

    </div>
  )
}
