import React,{useState,useEffect} from 'react'
import "./style.css"
import Logo from '../../assets/images/logo.png'
import { Link, NavLink } from 'react-router-dom'
import DrawerComponent from '../Drawer'
import MenuIcon from '@mui/icons-material/Menu';

export const HomePageNavbar = () => {

  // const menubtn = document.querySelector(".mobile-menu")
  // const menulist = document.querySelector(".nav-links");

  // menubtn.addEventListener('click',()=>{
  //   menulist.classList.toggle('hide')
  // })

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;

      if (scrollTop > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);


  return (
   <header className={`px-10 bg-transparent z-50 ${isScrolled ? 'header-scrolled' : ''}`}>
    <div className="headercontainer">
    <div className="logo">
        <img src={Logo}/>
    </div>
    <ul className="nav-links">
      <NavLink to={"/"}><li style={{color:'#fff'}} >Home</li></NavLink>
      <NavLink to={"/about"}><li style={{color:'#fff'}}>About us</li></NavLink>
      <NavLink to={"/Services"}><li style={{color:'#fff'}}>Services</li></NavLink>
      {/* <NavLink to={"/blogs"}><li style={{color:'#fff'}}>Blogs</li></NavLink> */}
      <NavLink to={"/products"}><li style={{color:'#fff'}}>Products</li></NavLink>
      <NavLink to={"/partners"}><li style={{color:'#fff'}}>Partners</li></NavLink>
      <NavLink to={"/careers"}><li style={{color:'#fff'}}>Careers</li></NavLink>
      <NavLink to={"/contact"}><li style={{color:'#fff'}}>Contact</li></NavLink>
      {/* <Link to={"/"}><span>Home</span></Link>
      <Link to={"/about"}><span>About us</span></Link>
      <Link to={"/services"}><span>Services</span></Link>
      <Link to={"/blogs"}><span>Blogs</span></Link>
      <Link to={"/partners"}><span>Partners</span></Link>
      <Link to={"/carrers"}><span>Careers</span></Link>
      <Link to={"/contact"}><span>Contact</span></Link> */}
    </ul>
    <div className="menu">
      <DrawerComponent menuColor={"#fff"}/>
      
    </div>
    <div className="mobile-menu">
      <MenuIcon/>
    </div>
    </div>
   </header>
  )
}
