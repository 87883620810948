import React from "react";
import "./style.css";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { AnimatePresence, motion } from "framer-motion";

const AccordionItem = ({ item, onClick }) => {
  return (
    <div>
      <button
        className="w-full text-left p-4 focus:outline-none bg-gray-100 rounded-lg shadow-md flex justify-between items-center my-4 font-medium"
        onClick={() => onClick(item.id)}
      >
        {item.question}
        {item.open ? <RemoveCircleIcon /> : <AddCircleIcon />}
      </button>
      <AnimatePresence>
        {item.open && (
          <motion.div
            key={item.id}
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            className="mt-2 ml-4"
          >
            <p>{item.answer}</p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default AccordionItem;
