import React from 'react'
import { Link } from 'react-router-dom'
import GifSource from '../../assets/images/ypf-energia.gif'
import { Helmet } from 'react-helmet'

const PageNotFound = () => {
  return (
<section className='w-full h-4/3 relative'>
<Helmet>
        <title>Page Not Found</title>
        </Helmet>
  <div className="flex flex-col h-screen gap-5 items-center justify-center">
    <img src={GifSource} alt="" className='h-32 object-contain w-32' />
    <h2 className='xl:text-8xl/tight md:text-6xl/tight sm:text-5xl/tight  font-semibold'>404</h2>

    <h5 className='lg:text-6xl md:text-5xl sm:text-3xl xl:text-6xl'>Page Not Found</h5>

    <p className='sm:text-xs lg:text-xl text-center'>We are sorry, the page you requested could not be found Please go back to homepage</p>

    <Link to={"/"}><button className='round rounded-full px-5 py-3 text-white bg-gray-400 hover:bg-white hover:text-green-600 sm:text-xs' >Go Back</button></Link>
    
  </div>
</section>

  )
}

export default PageNotFound