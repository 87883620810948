import React, { useState } from "react";
import "./style.css";
import Button from "@mui/material/Button";
import p1 from "../../../assets/images/p1.jpg";
import p2 from "../../../assets/images/p2.jpg";
import p3 from "../../../assets/images/p3.jpg";
import { useAnimation,motion, AnimatePresence} from "framer-motion";

// import data from "./data.json";

export const Gallery = () => {
  const data = 
  [
      {
        "id":1,
        "title": "SOLAR ROOF",
        "subheader": "Renewable Energy",
        "description": "It represents a clean, green source of energy. Solar power is a great way to reduce your carbon footprint.",
        "featureimg": p1,
        "bannerimg": "/images/img.jpg",
        "headermain": "Renewable Energy",
        "descriptionmain": "Your family deserves to be comfortable at home! We provide high quality repair services in the Atlanta Metro Area.We offer our residential, commercial, and industrial customers a full line of quality repair services in reasonable rates. Nulla porttitor accumsan tincidunt praesent sapien massa convallis pellen tesque necp egestas non nisi vivamus suscipite nulla porttitor accumsan tincidunt praesent sapien massa convallisa pellentesque. Lorem ipsum dolor sit met, consectetur adipiscing elit. Integer imperdiet iaculis ipsum aliquet ultricies. Sed a tincidunt enim. Mecenas ultraces viverra ligula, vel lobortis ante pulvinar sed. Doce erat magna, aliquot vitae semper vitae, accusant vel nis. Vivamus pellentesque orci sit met odio dictum eget kommod nulla consequent.",
        "Projectheader": "Project-Goals",
        "projectdescription": "Success is not a spectator sport, something that just happens before your eyes. It’s an experience, a game that must be played to be enjoyed fully. You need to get involved with life. You’ll need to get more involved with your family, friends, people you see every day. Because in that involvement, you’ll find you have everything you need to succeed.",
        "clientname": "Manhattan",
        "categories": "Solar Wind",
        "Date": "October 26,2022",
        "pdffile": "/pdffile/pdf1.pdf",
        "bgvideo": "/vedio.mp4"
      },
      {
        "id":2,
        "title": "SOLAR WIND ENERGY",
        "subheader": "Renewable Power Source",
        "description": "Solar wind energy combines the power of solar and wind technologies to create a sustainable energy source. By harnessing both solar radiation and wind power, this innovative approach maximizes energy production and reduces reliance on fossil fuels.",
        "featureimg": p2,
        "bannerimg": "/images/solar-wind-banner.jpg",
        "headermain": "Renewable Power Source",
        "descriptionmain": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed a tincidunt enim. Maecenas ultrices viverra ligula, vel lobortis ante pulvinar sed. Donec erat magna, aliquet vitae semper vitae, accumsan vel nisi.",
        "Projectheader": "Project Objectives",
        "projectdescription": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed a tincidunt enim. Maecenas ultrices viverra ligula, vel lobortis ante pulvinar sed. Donec erat magna, aliquet vitae semper vitae, accumsan vel nisi.",
        "clientname": "SolarTech Inc.",
        "categories": "Solar Wind",
        "Date": "November 15, 2022",
        "pdffile": "/pdffiles/solar-wind.pdf",
        "bgvideo": "/videos/solar-wind.mp4"
      },
      {
        "id":3,
        "title": "WIND TURBINE TECHNOLOGY",
        "subheader": "Harnessing Wind Power",
        "description": "Wind turbine technology utilizes the power of wind to generate electricity. These towering structures with rotating blades capture the kinetic energy of the wind and convert it into clean, renewable energy. Wind turbines are a key component of modern wind farms, providing sustainable electricity for communities around the world.",
        "featureimg": p3,
        "bannerimg": "/images/wind-turbine-banner.jpg",
        "headermain": "Harnessing Wind Power",
        "descriptionmain": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed a tincidunt enim. Maecenas ultrices viverra ligula, vel lobortis ante pulvinar sed. Donec erat magna, aliquet vitae semper vitae, accumsan vel nisi.",
        "Projectheader": "Project Goals",
        "projectdescription": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed a tincidunt enim. Maecenas ultrices viverra ligula, vel lobortis ante pulvinar sed. Donec erat magna, aliquet vitae semper vitae, accumsan vel nisi.",
        "clientname": "Wind Power Solutions",
        "categories": "Wind Turbine",
        "Date": "December 5, 2022",
        "pdffile": "/pdffiles/wind-turbine.pdf",
        "bgvideo": "/videos/wind-turbine.mp4"
      }
  
    ]
    
  const [items, setitems] = useState(data);

  const filterItems = (categories)=>{
    const updatedItems = data.filter((currentElement)=>{
      return currentElement.categories == categories;
    })
    setitems(updatedItems)
  }
  const [active,setActive]=useState(null)

  const control = useAnimation()

  const boxAnimation =  {
    visible:{opacity:1,scale:1,transition:{duration:0.5}},
    hidden:{opacity:0,scale:0.5}
  }

  return (
    <div>
      <div className="menu-tabs">
        <div className="menu-tab flex items-center justify-center gap-5">
          <button  onClick={()=>{setitems(data);setActive(null);control.start("visible")}} className={`${active == null ? 'bg-green-500 text-white w-10 rounded-3xl font-semibold': 'text-black '}`}>All</button>
          <button  onClick={()=>{filterItems('Solar Wind');setActive('Solar');control.start("visible")}} className={`${active == 'Solar' ? 'bg-green-500 text-white w-14 rounded-3xl font-semibold ': 'text-black '}`}>Solar</button>
          <button  onClick={()=>{filterItems('Wind Turbine');setActive('Wind');control.start("visible")}} className={`${active == 'Wind' ? 'bg-green-500 text-white w-14 rounded-3xl font-semibold ': 'text-black '}`}>Wind</button>
        </div>
      </div>
      <div className="menu-items contain-content my-10">
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4 md:mx-10 animate-fadein transition-all-300">
          <AnimatePresence>
          {items.map((obj) => {
            const { title, subheader, featureimg , id } = obj;

            return (
              <motion.div key={id} variants={boxAnimation} initial="hidden" animate={control} whileInView="visible" className="bg-gray-100 shadow-xl md:w-full h-5/5 relative z-10 rounded-lg">
                <img
                  src={featureimg}
                  alt="imh"
                  style={{
                    width: "auto",
                    height: "auto",
                    objectFit: "contain",
                    zIndex: 1,
                  }}
                  className="rounded-lg"
                />
                <div className="hover-content opacity-1 absolute inset-0 flex items-center justify-center  transition-opacity duration-300">
                  <div className="bg-white w-3/4 absolute bottom-14 p-5 rounded-xl slide-card cursor-pointer">
                    <h5 className="text-green-500">{title}</h5>
                    <p className="text text-2xl font-bold">{subheader}</p>
                  </div>
                </div>
              </motion.div>
            );
          })}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};
