import React, { useEffect, useState } from "react";
import { Stepper, Step, Button, Typography } from "@material-tailwind/react";
import {
  HomeIcon,
  CogIcon,
  UserIcon,
  BuildingLibraryIcon,
  TrashIcon,
  CreditCardIcon,
  TicketIcon,
  CheckBadgeIcon,
} from "@heroicons/react/24/outline";

import Step2 from "./Components/Step2";
import Step3 from "./Components/Step3";
import Step1 from "./Components/Step1";
import { Link, useLocation } from "react-router-dom";
import EmptyCartImg from "../../assets/images/empty-cart-1.png";
import { Helmet } from "react-helmet";

const BillingPage = () => {
  const location = useLocation();
  const url = "https://backend.nilaithal.com";
  // const {id,quantity} = location.state || {}

  // const products = [
  //   {
  //     productId:id,
  //     quantity:quantity,
  //   }
  // ]

  const [productData, setProductData] = useState([]);

  const[transactionData,setTransactionData]=useState('')


  useEffect(() => {
    const cookieData = JSON.parse(sessionStorage.getItem("cart"));
    setProductData(cookieData);
  }, []);




  const [activeStep, setActiveStep] = useState(0);
  const [isLastStep, setIsLastStep] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(false);
  const data = [
    {
      id: "ac1",
      name: "AC 3.3 kW charger with 3 to 5 universal sockets per one Smart Control Unit",
      voltage: "3.3 KW",
      price: 78000,
      currency: "INR",
      description: "A reliable AC charger for home use.",
      brand: "ChargerBrand",
      category: "AC",
      stock: 100,
      main_image:
        "https://ev.ideassion.in/wp-content/uploads/2024/04/image-removebg-preview-5-176x300.png",
      images: [
        "https://ev.ideassion.in/wp-content/uploads/2024/04/image-removebg-preview-4-198x300.png",
      ],
    },
    {
      id: "dc1",
      name: "30 kW DC (Fast) charger with single connector (CCS2)",
      voltage: "30KW",
      price: 57700,
      currency: "INR",
      description: "A compact DC charger for automotive use.",
      brand: "AutoCharge",
      category: "DC",
      stock: 75,
      main_image:
        "https://ev.ideassion.in/wp-content/uploads/2024/04/image-removebg-preview-4-198x300.png",
      images: [
        "https://example.com/images/dc1_side.jpg",
        "https://example.com/images/dc1_back.jpg",
      ],
    },
  ];


  const handleNext = () => {
    setActiveStep((cur) => cur + 1);
  };

  const handlePrev = () => {
    setActiveStep((cur) => Math.max(cur - 1, 0));
  };

  const handleBillingData = (data) => {
    setTransactionData(data)
    handleNext();
  };

  const getActiveComponents = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <Step1
            products={productData}
            handleNext={handleNext}
            billingData={handleBillingData}
            
          />
        );
      case 1:
        return <Step2 transactionData={transactionData}/>;
      // case 2:
      //   return <Step3 />;
      default:
        return <Step1 />;
    }
  };

  const handleRemoveCartItem = (index) => {

    const productIndex = productData.findIndex(
      (product) => product.id === index
    );

    productData.splice(productIndex);
    // parsedCart[productIndex].quantity = quantity;
    let updatedCart = JSON.stringify(productData);

    sessionStorage.setItem("cart", updatedCart);
    setProductData(updatedCart?JSON.parse(sessionStorage.getItem("cart")):[null]);

    // else {
    //   parsedCart.push({
    //     id: productId,
    //     quantity: quantity,
    //     name: data?.name,
    //     voltage: data?.voltage,
    //     image: data?.image[0].url,
    //   });
  };

  return (
    <>
    <Helmet>
      <title>CheckOut Products</title>
    </Helmet>
      <section className="min-h-screen flex flex-col items-center justify-center bg-[#f6f6f6] relative ">
        {productData?.length<=0? (
          <>
            <div className="h-full flex flex-col items-center justify-center gap-1">
              <div className="h-[300px] w-[300px]">
                <img
                  src={EmptyCartImg}
                  alt=""
                  className="h-full w-full object-contain"
                />
              </div>
              <h5>No Items in Cart</h5>
              <a href="/products" className="text-red-600">
                Go Back
              </a>
            </div>
          </>
        ) : (
          <div>
            <div className="w-full px-24 py-4 flex flex-col items-center relative h-[100px] my-5">
              <Stepper
                activeStep={activeStep}
                isLastStep={(value) => setIsLastStep(value)}
                isFirstStep={(value) => setIsFirstStep(value)}
                className="xl:w-1/2 w-full"
              >
                <Step onClick={() => setActiveStep(0)}>
                  <UserIcon className="h-5 w-5" />
                  <div className="absolute -bottom-[2.5rem] w-max text-center">
                    <h6
                      className={`${
                        activeStep === 0 ? "text-black" : "text-gray-500"
                      } font-medium text-xs lg:text-[16px] md:text-[16px]`}
                    >
                      Billing Address
                    </h6>
                  </div>
                </Step>
                <Step>
                  <CreditCardIcon className="h-5 w-5" />
                  <div className="absolute -bottom-[2.5rem] w-max text-center">
                    <h6
                      className={`${
                        activeStep === 1 ? "text-black" : "text-gray-500"
                      } font-medium text-xs lg:text-[16px] md:text-[16px]`}
                    >
                      Payment
                    </h6>
                  </div>
                </Step>
                {/* <Step onClick={() => setActiveStep(2)}>
                  <CheckBadgeIcon className="h-5 w-5" />
                  <div className="absolute -bottom-[2.5rem] w-max text-center">
                    <h6 className={`${activeStep === 2 ? "text-black":'text-gray-500'} font-medium text-xs lg:text-[16px] md:text-[16px]`}>
                      Completed
                    </h6>
                   
                  </div>
                </Step> */}
              </Stepper>
            </div>
            <div className=" z-10  lg:px-10 px-0 grid xl:grid-cols-[2fr,1fr] lg:grid-cols-[2fr,1fr] md:grid-cols-1 grid-flow-row gap-5 relative ">
              <div
                className="h-full bg-white rounded-lg border md:order-2 xl:order-1 lg:order-1 flex-col flex items-center w-full justify-center
               "
              >
                {getActiveComponents(activeStep)}
              </div>
              <div className="h-[500px] overflow-y-scroll bg-white overflow-x-hidden rounded-lg border py-4 lg:px-5 px-2 md:order-1 xl:order-2 lg:order-2">
                <div className="flex flex-col gap-4">
                  <h3 className="font-bold text-xl">
                    Your Cart ({productData == null ? 0 : productData.length})
                  </h3>
                  <hr />
                </div>
                {productData === null ? (
                  <>
                    <div className="h-[200px] flex flex-col items-center justify-center gap-1">
                      <div className="h-[100px] w-[100px]">
                        <img
                          src={EmptyCartImg}
                          alt=""
                          className="h-full w-full object-contain"
                        />
                      </div>
                      <h5>No Items in Cart</h5>
                      <a href="/products" className="text-red-600">
                        Go Back
                      </a>
                    </div>
                  </>
                ) : (
                  productData.map((item, index) => (
                    
                    <div className="flex border m-2 items-center gap-3 px-5 py-3 shadow-md rounded-lg lg:w-[400px] md:w-[95%] hover:border-green-500 ">
                      <div className="h-[70px] w-[70px]">
                        <img
                          src={`${url}${item.image}`}
                          alt={item.name}
                          className="h-full w-full object-contain"
                        />
                      </div>
                      <div className="flex flex-col gap-2 w-full">
                      <Link to={`/products/${item.name.replace(/\s+/g, '-').toLowerCase()}`} state={{productId:`${item.productId}`}}>
                        <h6 className="text-ellipsis line-clamp-1 text-[15px] font-semibold hover:text-green-500">
                          {item.name}
                        </h6>
                        </Link>
                        <p className="text-xs">{item.voltage}</p>
                        <div className="flex items-center justify-between w-full ">
                          <div className="flex flex-col xl:flex-row md:flex-row lg:flex-row items-start lg:items-center md:items-center xl:items-center lg:gap-5 md:gap-5 xl:gap-5 gap-2">
                          <p className="text-xs font-semibold">
                            Qty: {item.quantity}
                          </p>
                          <p className="text-xs font-semibold">
                            Price: ₹ {item.price*item.quantity}
                          </p>
                          

                          </div>
                          {
                            activeStep == 1 ? (<></>):(<button
                              className="text-xs font-bold text-red-600 flex items-center gap-1 hover:bg-red-600 hover:text-white  p-2 rounded-lg"
                              onClick={() => handleRemoveCartItem(item.productId)}
                            >
                              Remove <TrashIcon className="h-4 w-4" />
                            </button>)
                          }
                          
                        </div>
                      </div>
                    </div>
                  
                  ))
                )}
                <hr className="my-2" />
                {productData == null ? (
                  <></>
                ) : (
                  //   <div className="p-5 flex-col flex gap-4 border shadow-lg rounded-lg">
                  //   <h5 className="font-bold">Order Summary</h5>
                  //   <table className="w-full ">
                  //     <tr>
                  //       <td className="font-semibold p-2">Subtotal</td>
                  //       <td className="font-bold p-2">₹ {productData}</td>
                  //     </tr>
                  //     <tr>
                  //       <td className="font-semibold p-2">Shipping</td>
                  //       <td className="font-bold p-2">₹ 500</td>
                  //     </tr>
                  //     <tr>
                  //       <td className="font-semibold p-2">CGST</td>
                  //       <td className="font-bold p-2">₹ 120</td>
                  //     </tr>
                  //     <tr>
                  //       <td className="font-semibold p-2">SGST</td>
                  //       <td className="font-bold p-2">₹ 120</td>
                  //     </tr>

                  //     <tr className="border-t-2 p-5">
                  //       <td className="font-semibold p-3">Total</td>
                  //       <td className="font-bold p-3">₹ 97,740</td>
                  //     </tr>
                  //   </table>
                  //   {/* <p>{id}- {quantity}</p> */}
                  // </div>
                  <></>
                )}
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default BillingPage;
