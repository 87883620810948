import React, { useState } from 'react'
import BannerImg from "../../assets/images/services_page_title.jpg";
import BlogImg1 from "../../assets/images/bg1.jpg";
import BlogImg2 from "../../assets/images/bg2.jpg";
import BlogImg3 from "../../assets/images/service1.jpg";
import BlogImg4 from "../../assets/images/ev.jpg";
import { useNavigate } from 'react-router-dom';
export const Blogs = () => {
  const data = [
    {
      "category": "Green Energy",
      "title": "Solar Power: Harnessing the Sun's Energy",
      "author": "Emily Johnson",
      "date": "2024-04-12",
      "feature_image": BlogImg2,
      "banner_image": BlogImg2,
      "content": {
        "introduction": "Solar power is a renewable energy source derived from the sun's radiation.",
        "benefits": {
          "clean": "Solar power reduces greenhouse gas emissions and air pollution.",
          "sustainable": "Solar power is abundant and inexhaustible, making it a sustainable energy solution.",
          "cost-effective": "Solar power can lead to significant cost savings on electricity bills over time."
        },
        "conclusion": "Solar power is a key player in the transition to a clean and sustainable energy future."
      }
    },
    {
      "category": "Wind Energy",
      "title": "Wind Turbines: Capturing the Power of the Wind",
      "author": "Michael Anderson",
      "date": "2024-04-13",
      "feature_image": BlogImg1,
      "banner_image": BlogImg1,
      "content": {
        "introduction": "Wind turbines convert the kinetic energy of the wind into electricity.",
        "advantages": {
          "renewable": "Wind energy is a renewable resource that will never run out.",
          "clean": "Wind energy production does not produce harmful emissions like fossil fuels.",
          "versatile": "Wind turbines can be installed both onshore and offshore to capture wind energy."
        },
        "conclusion": "Wind turbines play a crucial role in the global transition to clean and renewable energy sources."
      }
    },
    {
      "category": "Wind Energy",
      "title": "Hydroelectric Dams: Powering the Future with Water",
      "author": "Alexandra Davis",
      "date": "2024-04-14",
      "feature_image": BlogImg3,
      "banner_image": BlogImg3,
      "content": {
        "introduction": "Hydropower harnesses the energy of flowing water to generate electricity.",
        "benefits": {
          "reliable": "Hydropower is a reliable source of energy that can provide baseload power.",
          "flexible": "Hydropower plants can quickly adjust their output to match changing electricity demand.",
          "environmentally friendly": "Hydropower production does not emit greenhouse gases and has minimal environmental impact when properly managed."
        },
        "conclusion": "Hydropower is a valuable component of the renewable energy mix, providing clean and reliable electricity."
      }
    },
    {
      "category": "Biomass Energy",
      "title": "Biomass Energy: Turning Waste into Power",
      "author": "David Wilson",
      "date": "2024-04-15",
      "feature_image": BlogImg1,
      "banner_image": BlogImg1,
      "content": {
        "introduction": "Biomass energy is produced from organic materials such as wood, crop residues, and waste.",
        "benefits": {
          "renewable": "Biomass is a renewable energy source that can be replenished relatively quickly.",
          "waste reduction": "Using biomass for energy can help divert organic waste from landfills, reducing methane emissions.",
          "local economic benefits": "Biomass energy production can create jobs and support local economies, especially in rural areas."
        },
        "conclusion": "Biomass energy offers a sustainable solution to meet energy needs while reducing waste and supporting local communities."
      }
    },
    {
      "category": "Energy Efficiency",
      "title": "Energy-Efficient Buildings: Designing for Sustainability",
      "author": "Sophia Martinez",
      "date": "2024-04-16",
      "feature_image": BlogImg2,
      "banner_image":BlogImg2,
      "content": {
        "introduction": "Energy-efficient buildings are designed to minimize energy consumption and environmental impact.",
        "features": {
          "insulation": "High-quality insulation reduces heat loss and improves energy efficiency.",
          "efficient appliances": "Using energy-efficient appliances and lighting fixtures can significantly reduce electricity consumption.",
          "renewable energy integration": "Integrating renewable energy sources such as solar panels or wind turbines can further reduce reliance on fossil fuels."
        },
        "conclusion": "Energy-efficient buildings are crucial for reducing greenhouse gas emissions and mitigating climate change."
      }
    },
    {
      "category": "Electric",
      "title": "Electric Vehicles: Driving Towards a Greener Future",
      "author": "Daniel Thompson",
      "date": "2024-04-17",
      "feature_image": BlogImg4,
      "banner_image": BlogImg4,
      "content": {
        "introduction": "Electric vehicles (EVs) are powered by electric motors and batteries instead of internal combustion engines.",
        "advantages": {
          "zero emissions": "EVs produce zero tailpipe emissions, reducing air pollution and greenhouse gas emissions.",
          "energy efficiency": "EVs are more energy-efficient than traditional vehicles, leading to lower fuel costs.",
          "renewable energy compatibility": "Charging EVs with renewable energy sources further reduces their environmental impact."
        },
        "conclusion": "Electric vehicles are an essential part of the transition to a sustainable transportation system."
      }
    },
    {
      "category": "Smart Grids",
      "title": "Smart Grids: Modernizing the Electricity Infrastructure",
      "author": "Olivia Garcia",
      "date": "2024-04-18",
      "feature_image": BlogImg3,
      "banner_image":BlogImg3,
      "content": {
        "introduction": "Smart grids are advanced electricity networks that integrate digital communication and automation technologies.",
        "features": {
          "real-time monitoring": "Smart grids enable real-time monitoring of electricity consumption, allowing utilities to optimize energy distribution.",
          "demand response": "Smart grids facilitate demand response programs, which incentivize consumers to adjust their electricity usage during peak demand periods.",
          "integration of renewable energy": "Smart grids support the integration of renewable energy sources by efficiently managing variable generation."
        },
        "conclusion": "Smart grids are essential for enhancing grid reliability, resilience, and sustainability."
      }
    },
    {
      "category": "Energy Storage",
      "title": "Energy Storage Technologies: Enabling Renewable Integration",
      "author": "Nathan Clark",
      "date": "2024-04-19",
      "feature_image": BlogImg2,
      "banner_image": BlogImg2,
      "content": {
        "introduction": "Energy storage technologies capture and store energy for later use, improving grid stability and reliability.",
        "types": {
          "battery storage": "Battery storage systems store electricity in rechargeable batteries, providing backup power and smoothing out fluctuations in renewable energy generation.",
          "pumped hydro storage": "Pumped hydro storage facilities use surplus electricity to pump water to higher elevations, which can be released later to generate electricity during peak demand periods.",
          "thermal storage": "Thermal storage systems store heat energy for later use, often in the form of molten salts or phase change materials."
        },
        "conclusion": "Energy storage technologies play a critical role in maximizing the benefits of renewable energy and accelerating the transition to a low-carbon future."
      }
    },
    {
      "category": "Sustainable Cities",
      "title": "Sustainable Cities: Building Resilient Urban Communities",
      "author": "Ava Taylor",
      "date": "2024-04-20",
      "feature_image": BlogImg1,
      "banner_image": BlogImg1,
      "content": {
        "introduction": "Sustainable cities are designed to meet the needs of present and future generations while minimizing environmental impact.",
        "features": {
          "green infrastructure": "Sustainable cities incorporate green spaces, parks, and rooftop gardens to improve air quality, mitigate urban heat islands, and support biodiversity.",
          "efficient transportation": "Sustainable cities prioritize public transit, cycling infrastructure, and walkability to reduce traffic congestion and air pollution.",
          "energy-efficient buildings": "Sustainable cities promote energy-efficient building practices and renewable energy integration to reduce energy consumption and carbon emissions."
        },
        "conclusion": "Sustainable cities are essential for addressing climate change, enhancing quality of life, and fostering resilient urban communities."
      }
    },
    {
      "category": "Renewable Energy Policy",
      "title": "Renewable Energy Policy: Driving the Transition to Clean Energy",
      "author": "Ethan Miller",
      "date": "2024-04-21",
      "feature_image": BlogImg4,
      "banner_image": BlogImg4,
      "content": {
        "introduction": "Renewable energy policies and regulations play a crucial role in accelerating the transition to clean energy.",
        "key_policy_areas": {
          "renewable energy targets": "Setting ambitious renewable energy targets encourages investment in clean energy technologies and deployment.",
          "financial incentives": "Financial incentives such as tax credits, grants, and feed-in tariffs incentivize renewable energy development and adoption.",
          "grid modernization": "Grid modernization policies support the integration of renewable energy sources by upgrading and expanding the electricity grid."
        },
        "conclusion": "Strong and supportive renewable energy policies are essential for achieving climate goals and building a sustainable energy future."
      }
    },
    {
      "category": "Carbon Capture and Storage",
      "title": "Carbon Capture and Storage: Mitigating Climate Change",
      "author": "Isabella Wilson",
      "date": "2024-04-22",
      "feature_image": BlogImg3,
      "banner_image": BlogImg3,
      "content": {
        "introduction": "Carbon capture and storage (CCS) technologies capture CO2 emissions from industrial processes and store them underground.",
        "benefits": {
          "emissions reduction": "CCS can significantly reduce CO2 emissions from power plants, industrial facilities, and other sources.",
          "climate mitigation": "CCS plays a crucial role in mitigating climate change by reducing greenhouse gas concentrations in the atmosphere.",
          "carbon utilization": "Captured CO2 can be utilized for enhanced oil recovery, producing valuable products, or permanently stored underground."
        },
        "conclusion": "Carbon capture and storage is a vital tool for achieving net-zero emissions and addressing climate change."
      }
    },
    {
      "category": "Offshore Wind Farms",
      "title": "Offshore Wind Farms: Harvesting Clean Energy from the Sea",
      "author": "Liam Johnson",
      "date": "2024-04-23",
      "feature_image": BlogImg1,
      "banner_image": BlogImg1,
      "content": {
        "introduction": "Offshore wind farms are large-scale renewable energy projects located in bodies of water, typically in coastal areas or the open sea.",
        "advantages": {
          "abundant wind resources": "Offshore wind farms benefit from stronger and more consistent wind resources compared to onshore locations.",
          "minimized land use conflicts": "Locating wind farms offshore reduces land use conflicts and visual impacts while maximizing energy production potential.",
          "economic benefits": "Offshore wind projects create jobs, stimulate local economies, and contribute to energy security and independence."
        },
        "conclusion": "Offshore wind farms are a critical component of the renewable energy transition, offering abundant and sustainable energy resources."
      }
    },
    {
      "category": "Biofuels",
      "title": "Biofuels: A Sustainable Alternative to Fossil Fuels",
      "author": "Sophie Brown",
      "date": "2024-04-24",
      "feature_image": BlogImg2,
      "banner_image": BlogImg2,
      "content": {
        "introduction": "Biofuels are liquid fuels derived from biomass sources such as crops, agricultural residues, and organic waste.",
        "types": {
          "ethanol": "Ethanol is produced from crops such as corn, sugarcane, and wheat through fermentation and distillation processes.",
          "biodiesel": "Biodiesel is made from vegetable oils, animal fats, or recycled cooking oils through a chemical process called transesterification."
        },
        "benefits": {
          "renewable": "Biofuels are renewable energy sources that can be produced from sustainable biomass feedstocks.",
          "carbon neutral": "Biofuels can be carbon neutral or even carbon negative when produced from waste materials or sustainably managed feedstocks.",
          "energy security": "Biofuels reduce dependence on fossil fuels and enhance energy security by diversifying fuel sources."
        },
        "conclusion": "Biofuels offer a promising pathway to decarbonize transportation and reduce reliance on fossil fuels."
      }
    },
    {
      "category": "Solar Farms",
      "title": "Solar Farms: Large-Scale Solar Installations",
      "author": "Noah Taylor",
      "date": "2024-04-25",
      "feature_image": BlogImg1,
      "banner_image": BlogImg1,
      "content": {
        "introduction": "Solar farms are large-scale installations that harness solar energy to generate electricity.",
        "advantages": {
          "scalability": "Solar farms can be scaled up to meet the energy needs of communities, cities, or even entire regions.",
          "low operating costs": "Once installed, solar farms have low operating costs and require minimal maintenance.",
          "land use compatibility": "Solar farms can be built on marginal or unused land, reducing competition with agricultural or urban areas."
        },
        "conclusion": "Solar farms play a crucial role in expanding renewable energy capacity and reducing greenhouse gas emissions."
      }
    }
  ]

  const [items, setitems] = useState(data);

  const filterItems = (categories)=>{
    const updatedItems = data.filter((currentElement)=>{
      return currentElement.category === categories;
    })
    setitems(updatedItems)
  }
  const [active,setActive]=useState(null)

  const navigate = useNavigate()


  const handleNavigation = (data)=>{
    navigate("/blog-details",{state:data})
  }
  
  return (
    <div>
              <section
        className="grid grid-cols-1 sm:grid-cols-2  h-full px-10 mb-5"
        style={{ backgroundColor: "#f9fbf5" }}
      >
        <div className="md:py-10 sm:py-5  justify-center flex flex-col h-full">
          <h4 className="sub-text  font-semibold text-xs">LATEST NEWS</h4>
          <div
            className="flex flex-col justify-between  gap-8"
            style={{ width: "70%" }}
          >
            <h2 className="text-left font-bold  lg:text-5xl">
            "Explore the Future of Renewable Energy" 
            </h2>
            <p className="decription">
            Stay up to date with the latest developments, innovations, and milestones at NILAITHAL.
            </p>
          </div>
        </div>
        <div className="justify-center flex flex-col h-full">
          <img src={BannerImg} alt="" style={{ objectFit: "contain" }} />
        </div>
      </section>
      <section className='my-10'>
      <div className="menu-tabs">
        <div className="menu-tab flex items-center justify-center gap-5">
          <button  onClick={()=>{setitems(data);setActive(null)}} className={`${active == null ? 'bg-green-500 text-white w-10 rounded-3xl font-semibold': 'text-black '}`}>All</button>
          <button  onClick={()=>{filterItems('Solar Farms');setActive('Solar')}} className={`${active === 'Solar' ? 'bg-green-500 text-white w-14 rounded-3xl font-semibold ': 'text-black '}`}>Solar</button>
          <button  onClick={()=>{filterItems('Green Energy');setActive('Wind')}} className={`${active === 'Wind' ? 'bg-green-500 text-white w-14 rounded-3xl font-semibold ': 'text-black '}`}>Wind</button>
          <button  onClick={()=>{filterItems('Biofuels');setActive('Biofuels')}} className={`${active === 'Biofuels' ? 'bg-green-500 text-white w-16 rounded-3xl font-semibold ': 'text-black '}`}>Biofuels</button>
          <button  onClick={()=>{filterItems('Electric');setActive('Electric')}} className={`${active === 'Electric' ? 'bg-green-500 text-white w-16 rounded-3xl font-semibold ': 'text-black '}`}>Electric</button>
        </div>
      </div>
      <div className="menu-items contain-content my-10 px-10">
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4 md:mx-10">
          {items.map((obj,index) => {
            const {title,feature_image,category,date, } = obj;

            return (
              // <div class="bg-gray-100 shadow-xl md:w-full h-5/5 relative z-10 rounded-lg">
              //   <img
              //     src={feature_image}
              //     alt="imh"
              //     style={{
              //       width: "auto",
              //       height: "auto",
              //       objectFit: "contain",
              //       zIndex: 1,
              //     }}
              //     className="rounded-lg"
              //   />
              //   <div class="hover-content opacity-1 absolute inset-0 flex items-center justify-center  transition-opacity duration-300">
              //     <div class="bg-white w-3/4 absolute bottom-14 p-5 rounded-xl slide-card cursor-pointer">
              //       <h5 className="text-green-500">{title}</h5>
              //       <p className="text text-2xl font-bold">{subheader}</p>
              //     </div>
              //   </div>
              // </div>
              <div className='flex flex-col gap-5 shadow-lg rounded-xl p-3 border-2 border-gray-100 hover:border-green-500 hover:text-green-500' key={index} onClick={()=>handleNavigation(data[index])}>
                <img src={feature_image} alt="" />
                <h2 className='font-bold text-xl'>{title}</h2>
                <div className='flex flex-row gap-4 items-center'>
                  <span className='text-white px-4 py-2 rounded-xl font-medium' style={{backgroundColor:"#469d2c"}}>
                    {category}
                  </span>
                  <span className='font-medium text-green-500'>{date}</span>

                </div>

              </div>
            );
          })}
        </div>
      </div>
      </section>
    </div>
  )
}
