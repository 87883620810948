import React, { useState } from "react";
import BannerImg from '../../assets/images/careersb2.jpg'
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CodeIcon from '@mui/icons-material/Code';
import HandymanIcon from '@mui/icons-material/Handyman';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import WebhookIcon from '@mui/icons-material/Webhook';
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import "./style.css"
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
export const Careers = () => {
  const companyValues = [
    {
      "title": "Impactful Work",
      "description": "At NILAITHAL, your work matters. Join a team that is at the forefront of the renewable energy revolution, working on projects that have a tangible impact on the environment and society."
    },
    {
      "title": "Continuous Learning",
      "description": "We are committed to your professional growth and development. Through training programs, mentorship opportunities, and hands-on experience, you'll have the chance to expand your skills and expertise in renewable energy and sustainability."
    },
    {
      "title": "Innovative Environment",
      "description": "We encourage innovation and creativity at every level. Whether you're an engineer, scientist, marketer, or business professional, you'll have the freedom to explore new ideas, experiment with cutting-edge technologies, and drive innovation in your area of expertise."
    },
    {
      "title": "Collaborative Culture",
      "description": "Collaboration is key to our success. At NILAITHAL, you'll work closely with talented and diverse teams across disciplines, leveraging each other's strengths and expertise to achieve our shared goals."
    },
    {
      "title": "Global Impact",
      "description": "Join a company that is making a difference on a global scale. From developing renewable energy projects in underserved communities to advocating for sustainable policies and practices, you'll be part of a team that is driving positive change around the world."
    }
  ];

  const jobOpenings = [
    {
      "id": 1,
      "category": "Engineering",
      "title": "Senior Renewable Energy Engineer",
      "location": "New Delhi, India",
      "type": "Full Time",
      "description": "We are seeking a Senior Renewable Energy Engineer to lead the design and implementation of renewable energy projects...",
      "requirements": [
        "Bachelor's degree in Engineering or related field",
        "Minimum of 5 years of experience in renewable energy project development",
        "Strong understanding of solar and wind energy systems",
        "Excellent communication and project management skills"
      ],
      "responsibilities": [
        "Lead the design and engineering of renewable energy projects",
        "Coordinate with internal and external stakeholders to ensure project success",
        "Conduct technical feasibility studies and performance assessments",
        "Provide technical guidance and support to project teams"
      ]
    },
    {
      "id": 2,
      "category": "Engineering",
      "title": "Junior Renewable Energy Engineer",
      "location": "Mumbai, India",
      "type": "Full Time",
      "description": "We are looking for a Junior Renewable Energy Engineer to support the design and implementation of renewable energy projects...",
      "requirements": [
        "Bachelor's degree in Engineering or related field",
        "Experience in renewable energy project development is a plus",
        "Basic understanding of solar and wind energy systems",
        "Strong problem-solving and communication skills"
      ],
      "responsibilities": [
        "Assist in the design and engineering of renewable energy projects",
        "Support project coordination and stakeholder communication",
        "Conduct research and analysis on renewable energy technologies",
        "Participate in technical meetings and project reviews"
      ]
    },
    {
      "id": 3,
      "category": "Marketing",
      "title": "Head of Branding",
      "location": "Bengaluru, India",
      "type": "Full Time",
      "description": "We are looking for a dynamic Head of Branding to lead our brand strategy and identity...",
      "requirements": [
        "Bachelor's degree in Marketing, Communications, or related field",
        "Minimum of 7 years of experience in brand management or marketing",
        "Proven track record of developing and implementing successful brand strategies",
        "Excellent leadership and strategic planning skills"
      ],
      "responsibilities": [
        "Develop and implement brand strategies to enhance brand awareness and perception",
        "Lead brand positioning, messaging, and creative direction",
        "Collaborate with cross-functional teams to ensure brand consistency across all channels",
        "Analyze market trends and consumer insights to inform brand strategy"
      ]
    },
    {
      "id": 4,
      "category": "Design",
      "title": "UI/UX Designer",
      "location": "Chennai, India",
      "type": "Full Time",
      "description": "We are seeking a talented UI/UX Designer to create engaging and intuitive user experiences for our digital platforms...",
      "requirements": [
        "Bachelor's degree in Design, Human-Computer Interaction, or related field",
        "Proven experience in UI/UX design for web and mobile applications",
        "Proficiency in design tools such as Sketch, Adobe XD, or Figma",
        "Strong portfolio showcasing design projects and problem-solving skills"
      ],
      "responsibilities": [
        "Collaborate with product management and development teams to define user requirements and design objectives",
        "Create wireframes, prototypes, and visual designs for web and mobile applications",
        "Conduct user research and usability testing to iterate and improve design solutions",
        "Work closely with developers to ensure design integrity and implementation"
      ]
    },
    {
      "id": 5,
      "category": "Development",
      "title": "Senior Software Developer",
      "location": "Hyderabad, India",
      "type": "Full Time",
      "description": "We are seeking a Senior Software Developer to lead the development of our renewable energy management platform...",
      "requirements": [
        "Bachelor's degree in Computer Science or related field",
        "Minimum of 5 years of experience in software development",
        "Proficiency in programming languages such as Java, Python, or JavaScript",
        "Experience with cloud technologies and microservices architecture"
      ],
      "responsibilities": [
        "Lead the design and development of scalable and maintainable software solutions",
        "Collaborate with cross-functional teams to define requirements and prioritize features",
        "Write clean and efficient code following best practices and coding standards",
        "Conduct code reviews and provide mentorship to junior developers"
      ]
    },
    {
      "id": 6,
      "category": "Development",
      "title": "Junior Software Developer",
      "location": "Pune, India",
      "type": "Full Time",
      "description": "We are looking for a Junior Software Developer to assist in the development of our renewable energy management platform...",
      "requirements": [
        "Bachelor's degree in Computer Science or related field",
        "Experience with software development projects or internships is a plus",
        "Basic understanding of programming languages and software development concepts",
        "Strong problem-solving and analytical skills"
      ],
      "responsibilities": [
        "Assist in the design, development, and testing of software solutions",
        "Collaborate with senior developers to implement features and enhancements",
        "Conduct code reviews and participate in team meetings",
        "Learn and grow as a software developer through hands-on experience"
      ]
    },
    {
      "id": 7,
      "category": "Design",
      "title": "Senior Graphic Designer",
      "location": "Jaipur, India",
      "type": "Full Time",
      "description": "We are seeking a Senior Graphic Designer to lead the design and production of visual assets for our marketing campaigns...",
      "requirements": [
        "Bachelor's degree in Graphic Design, Visual Communication, or related field",
        "Minimum of 5 years of experience in graphic design or visual communication",
        "Proficiency in design software such as Adobe Creative Suite",
        "Strong portfolio showcasing design projects and creative skills"
      ],
      "responsibilities": [
        "Lead the conceptualization and execution of visual design projects",
        "Create engaging and impactful visual assets for print and digital channels",
        "Collaborate with marketing and content teams to develop creative concepts",
        "Ensure brand consistency and quality across all design deliverables"
      ]
    },
    {
      "id": 8,
      "category": "Design",
      "title": "Junior Graphic Designer",
      "location": "Ahmedabad, India",
      "type": "Full Time",
      "description": "We are looking for a Junior Graphic Designer to assist in the creation of visual assets for our marketing campaigns...",
      "requirements": [
        "Bachelor's degree in Graphic Design, Visual Communication, or related field",
        "Experience with graphic design projects or internships is a plus",
        "Basic proficiency in design software such as Adobe Creative Suite",
        "Creative mindset and willingness to learn and grow"
      ],
      "responsibilities": [
        "Assist in the development and production of visual design projects",
        "Create compelling visual assets for various marketing channels",
        "Collaborate with senior designers and marketing teams to execute creative concepts",
        "Receive feedback and iterate on designs to achieve desired outcomes"
      ]
    },
    {
      "id": 9,
      "category": "Design",
      "title": "UI/UX Designer",
      "location": "Bengaluru, India",
      "type": "Full Time",
      "description": "We are seeking a talented UI/UX Designer to create engaging and intuitive user experiences for our digital platforms...",
      "requirements": [
        "Bachelor's degree in Design, Human-Computer Interaction, or related field",
        "Proven experience in UI/UX design for web and mobile applications",
        "Proficiency in design tools such as Sketch, Adobe XD, or Figma",
        "Strong portfolio showcasing design projects and problem-solving skills"
      ],
      "responsibilities": [
        "Collaborate with product management and development teams to define user requirements and design objectives",
        "Create wireframes, prototypes, and visual designs for web and mobile applications",
        "Conduct user research and usability testing to iterate and improve design solutions",
        "Work closely with developers to ensure design integrity and implementation"
      ]
    },
    {
      "id": 10,
      "category": "Operations",
      "title": "Field Service Technician",
      "location": "Chandigarh, India",
      "type": "Mobile",
      "description": "We are seeking a Field Service Technician to provide on-site support and maintenance for renewable energy systems...",
      "requirements": [
        "Diploma or Associate degree in Electrical or Mechanical Engineering",
        "Experience in installation, troubleshooting, and repair of electrical and mechanical systems",
        "Ability to work independently and travel to customer sites as needed",
        "Strong problem-solving and communication skills"
      ],
      "responsibilities": [
        "Install, commission, and maintain renewable energy systems at customer sites",
        "Troubleshoot and diagnose issues with electrical and mechanical components",
        "Perform preventive maintenance tasks to ensure system reliability",
        "Provide technical support and training to customers"
      ]
    }
  ];
  
  const JobOpeningcategories = [
    "All",
    "Engineering",
    "Marketing",
    "Design",
    "Development",
    "Business",
    "Operations",
    "Research & Development"
  ];
  

  const [items, setitems] = useState(jobOpenings);

  const filterItems = (categories)=>{
    const updatedItems = jobOpenings.filter((currentElement)=>{
      return currentElement.category === categories;
    })
    setitems(updatedItems)
  }
  const [active,setActive]=useState("All")

  const[value,setValue]=useState()

  const handleValueChange = (event)=>{
    setValue(event.target.value)

  }

  const navigate = useNavigate()

  const hanldeViewJob = (data)=>{
    navigate('/job-details',{state:data})

  }
  
  return (
    <div>
               <Helmet>
        <title>Careers</title>
        </Helmet>
      <section
        className="grid grid-cols-1 sm:grid-cols-2  h-full px-10"
        style={{ backgroundColor: "#f9fbf5" }}
      >
        <div className="md:py-10 sm:py-5  justify-center flex flex-col h-full">
          <h4 className="sub-text  font-semibold text-xs">Careers</h4>
          <div
            className="flex flex-col justify-between  gap-8"
            style={{ width: "90%" }}
          >
            <h2 className="text-left font-bold  lg:text-5xl/tight">
            Are you passionate about renewable energy and sustainability?
            </h2>
            <p className="decription">
            Join us in shaping the future of renewable energy and sustainability. At <span className="text-green-500 font-medium" style={{fontSize:17}}>NILAITHAL</span>, we are passionate about driving positive change and creating a world powered by clean, renewable energy. We believe in the power of innovation, collaboration, and environmental stewardship to make a meaningful impact on the planet and the lives of people everywhere.
            </p>
          </div>
        </div>
        <div className="justify-center flex flex-col h-full">
          <img src={BannerImg} alt="" style={{ objectFit: "contain" }} />
        </div>
      </section>
      <section className="my-10 flex flex-col items-center">
        <h2 className="text-4xl text-center font-bold">Our Culture</h2>
        <div className="shadow-xl w-4/5 p-10 rounded-xl">
        <p className="text-center">At <span className="text-green-500 font-medium">NILAITHAL</span>, we foster a culture of innovation, integrity, and inclusivity. We believe in empowering our employees to reach their full potential and thrive in a supportive and collaborative work environment. Here, you'll have the opportunity to work alongside industry experts, contribute to cutting-edge projects, and make a real difference in the world. </p>
      </div>
      </section>
      <section className="my-10 flex flex-col items-center px-10 gap-5">
        <h2 className="text-4xl text-center font-bold">Why Join <span className="text-green-500 text-4xl ">NILAITHAL ?</span></h2>

        <p className="text-center">At <span className="text-green-500 font-medium">NILAITHAL</span>, we foster a culture of innovation, integrity, and inclusivity. We believe in empowering our employees to reach their full potential and thrive in a supportive and collaborative work environment. Here, you'll have the opportunity to work alongside industry experts, contribute to cutting-edge projects, and make a real difference in the world. </p>

      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-[2fr,1fr,] lg:grid-cols-[2fr,1fr,1fr] gap-4 mt-5">
          {
            companyValues.map((obj,index)=>
              (
                <div  className="p-4 flex flex-col gap-4 shadow-md rounded-md hover:border-green-500 hover:border-2 hover:textwhite transition-all ease-in border-2" key={index} >
            <div className="flex flex-col items-start hover:text-white">
              <CheckCircleIcon
                className="text-center"
                style={{ textAlign: "center", color: "#469d2c", fontSize: 40 }}
              />
            </div>
            <h5 className="font-bold text-xl">{obj.title}</h5>
            <p className="description">
              {obj.description}
            </p>
          </div>
              )
            )
          }
        </div>
      </section>
      <section className="my-10 flex flex-col items-center px-10 gap-5">
        <h2 className="text-4xl text-center font-bold">Current Positions</h2>

        {/* <p className="text-center">Lorem ipsum dolor sit amet consectetur purus convallis cursus turpis in et pulvinar viverra.</p> */}

        <div className="tab-bar flex flex-row gap-5">


          {
            JobOpeningcategories.map((obj,index)=>(
      
             
              <div className="tab-btns" key={index}>
              <button  onClick={()=>{ {obj ==='All' ? setitems(jobOpenings): filterItems(`${obj}`);} setActive(`${obj}`)}} className={`${active === `${obj}` ? 'text-black active:border-green-500 active:border-b-4 font-semibold border-green-500 border-b-2': 'text-black hover:text-green-500 '}`}>{obj}</button>
              </div>


             
            ))
          }
                  <FormControl fullWidth className="dropdown-select w-full" sx={{ // Define styles for FormControl
        '& .MuiNativeSelect-root': { // Define styles for NativeSelect
          color: '#469d2c', 
          fontWeight:'semibold',// Default color for the NativeSelect
          '&:hover': { // Styles for hover state
            backgroundColor: '#fff',
            borderColor:'#469d2c' // Background color on hover
          },
          '&:focus': { // Styles for focus state
            color: '#469d2c',
            borderColor:'#469d2c',
            borderBottomColor:'#469d2c' // Change color on focus
          },
        },
      }}>

                  <NativeSelect defaultValue={"All"} inputProps={{
                    name:'select',
                    id:'uncontrolled-native'
                  }}
                  onChange={ (event)=> {
                    event.target.value === "All" ?
                    setitems(jobOpenings) : filterItems(event.target.value)
                  }
                    // filterItems(event.target.value)
                  } 
                   >
                    {
                      JobOpeningcategories.map((obj,index)=>(
                        <option value={obj} key={index}>{obj}</option>
                      ))
                    }
                   

                  </NativeSelect>
                 </FormControl>
          
        </div>

      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-[1fr,1fr,] lg:grid-cols-[1fr,1fr] gap-10 mt-5 w-11/12">
        {
          items.map((obj,index)=>{
            const {title,type,category,location,description,id}=obj
            return(
              <div key={id} className="flex flex-col gap-3 shadow-md px-4 py-4 rounded-md hover:border-green-500 border-2" onClick={()=>hanldeViewJob(items[index])}>
                <CodeIcon/>
                <div className="flex flex-row justify-between">
                  <h2 className="font-bold text-xl">{title}</h2>
                  <ArrowCircleRightRoundedIcon style={{color:'#469d2c',fontSize:40}}/>
                </div>
                <p className="text-gray-500">{description}</p>
                <div className="flex flex-row gap-2">
                <span className="font-medium text-green-500">{location}</span>
                <span className="text-red-600 font-bold">--{type}</span>
                </div>
              </div>
            )
            
          })
        }
          {/* {
            companyValues.map((obj,index)=>
              (
                <div className="p-4 flex flex-col gap-4 shadow-md rounded-md hover:border-green-500 hover:border-2 hover:textwhite transition-all ease-in border-2" key={index}>
            <div className="flex flex-col items-start hover:text-white">
              <CheckCircleIcon
                className="text-center"
                style={{ textAlign: "center", color: "#469d2c", fontSize: 40 }}
              />
            </div>
            <h5 className="font-bold text-xl">{obj.title}</h5>
            <p className="description">
              {obj.description}
            </p>
          </div>
              )
            )
          } */}
        </div>
      </section>
     
    </div>
  );
};
