import { CalendarMonth, CalendarViewMonthOutlined } from '@mui/icons-material'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const ReadBlog = () => {
    const data = useLocation()

    const {category,title,author,date,feature_image,banner_image,content}=data.state


  return (
    <div>
        <section className='flex flex-col items-center justify-center gap-10 text-white' style={{
            background: `linear-gradient(rgba(0, 0, 0, 0.651),rgba(0, 0, 0, 0.463)),url(${banner_image}) no-repeat`,
            minHeight:'80vh',
            backgroundPosition:'center',
            backgroundSize:'cover'
        }}>
            <h6 className='text-white font-medium text-xl'>{category}</h6>
            <h2 className='text-white stroke-white lg:text-5xl sm:text-2xl font-bold uppercase text-center'>{title}</h2>
            {/* {
                sliderImg.map((obj)=>(
                    <img src={obj} alt="" />
                ))
            } */}



            <div className="flex"> 
            <p className='text-white'>
                
                <CalendarMonth/> {date} / <span className='text-white after:contents'>{author}</span>

            </p>
            
            </div>

        </section>

        <section className='my-10 flex flex-col gap-5 px-10'>

            <h2 className='text-4xl font-medium'>Introduction</h2>

            <p className='ml-5'  style={{fontSize:16}}> 
                {content.introduction}
            </p>

            <h2 className='text-4xl font-medium'>Advantages</h2>

            
            <p className=' ml-5' style={{fontSize:16}}> 
                {content.advantages?.renewable}
            </p>
            <p className=' ml-5'  style={{fontSize:16}}> 
                {content.advantages?.clean}
            </p>
            <p className=' ml-5'  style={{fontSize:16}}> 
                {content.advantages?.versatile}
            </p>

        </section>
    </div>
  )
}

export default ReadBlog