import { ArrowUpward } from '@mui/icons-material'
import { Button } from '@mui/material'
import React, { useState } from 'react'

const ScrollBackToTop = () => {
    const [visible,setVisible]=useState(false)

    const toggleVisible = ()=>{
        const scrolled = document.documentElement.scrollTop;

        if(scrolled > 300){
            setVisible(true)
        }
        else if(scrolled <=300){
            setVisible(false)
        }
    }

  const scrollToTop = ()=>{
    window.scrollTo({
        top:0,
        behavior:'smooth'
    })
  };

  window.addEventListener('scroll',toggleVisible)

  return (
    <Button onClick={scrollToTop} style={{display:visible ? 'block':'none',position:'fixed',bottom:20,right:10,background:'#5d9b3e',borderRadius:500,padding:20}} className='rounded-full animate-fadeinup transition-all duration-100 '>
        <ArrowUpward style={{color:'white',fontSize:25}} className='animate-slideinup'/>
    </Button>
  )
}

export default ScrollBackToTop