import React, { useRef, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import Controls from "./Controls";
import "./style.css";
import { Container } from "@mui/material";
import { formatTime } from "./FomatTime";

let count = 0

const VideoPlayer = ({ videofile }) => {

 const videoplayerRef = useRef(null)
 const controlRef = useRef(null)
  const [videoState, setVideoState] = useState({
    playing: true,
    muted: true,
    volume: 0.5,
    played: 0,
    seeking: false,
    buffer: true,
  });

  const { played, playing, muted, volume, seeking, buffer } = videoState;

  const playPauseHanlder = () => {
    setVideoState({ ...videoState, playing: !videoState.playing });
  };

  const rewindHandler = ()=>{
    videoplayerRef.current.seekTo(videoplayerRef.current.getCurrentTime() - 5)
  }

  const fastForwardHanlder = ()=>{
    videoplayerRef.current.seekTo(videoplayerRef.current.getCurrentTime() + 10)
  }

 

  const progressHanlder = (state)=>{
    // if (count > 3) {
        
    //     controlRef.current.style.visibility = "hidden"; // toggling player control container
    //   } else if (controlRef.current.style.visibility === "visible") {
    //     count += 1;
    //   }

    if(!seeking){
        setVideoState({...videoState,...state})
    }
  }

  const handleVisibility = ()=>{
    controlRef.current.style.visibility = "visible"
  }

  const handleHiddenVisibility = ()=>{
    controlRef.current.style.visibility = "hidden"
  }

  const mouseMoveHandler = () => {
    controlRef.current.style.visibility = "visible";
  count = 0;
  };
  const seekHanlder = (e,value)=>{
    setVideoState({...videoState, played:parseFloat(value) / 100})
  }

  const seekMouseUpHandler = (e,value)=>{
    setVideoState({...videoState,seeking:false});
    videoplayerRef.current.seekTo(value / 100)
  }

  const onSeekMouseDownHandler = (e) => {
    setVideoState({ ...videoState, seeking: true });
  };

  const volumeChangeHanlder = (e,value)=>{
    const newVolume = parseFloat(value) / 100;
    setVideoState({
        ...videoState,
        volume:newVolume,
        muted:Number(newVolume) === 0 ? true : false
    })
  }

  const volumeSeekUpHandler = (e,value)=>{
    const newVolume = parseFloat(value)/100;
    setVideoState({
        ...videoState,
        volume:newVolume,
        muted:newVolume ===0 ? true : false


    })
  }
  const muteHanlder = ()=>{
    setVideoState({...videoState,muted:!videoState.muted})
  }

  const currentTime = videoplayerRef.current ? videoplayerRef.current.getCurrentTime():"00:00";

  const duration = videoplayerRef.current? videoplayerRef.current.getDuration(): "00:00";

  
  const formatCurrentTime = formatTime(currentTime)

  const formatDuration = formatTime(duration)



  
  const bufferStartHandler = () => {
    ("Bufering.......");
      setVideoState({...videoState , buffer: true})
    };
   
    const bufferEndHandler = () => {
    // console.log("buffering stoped ,,,,,,play");
      setVideoState({...videoState , buffer: false})
    };


  return (
    <div className="sm:hidden lg:block md:block flex flex-col items-center justify-center hide_videoplayer">
      <Container maxWidth="md" sx={{justifyItems:'center'}}>
        <div className="player_wrapper" onMouseEnter={handleVisibility} onMouseLeave={handleHiddenVisibility}>
          <ReactPlayer
          ref={videoplayerRef}
            url={videofile}
            // url="https://youtu.be/FSnAllHtG70"
            // controls={true}
            className="player"
            width="100%"
            height="100%"
            playing={playing}
            muted={muted}
            volume={volume}
            onProgress={progressHanlder}
            onBuffer={bufferStartHandler}
            onBufferEnd={bufferEndHandler}
        
          />
          {buffer && <p>Loading</p>}
          <Controls controlref={controlRef} onPlayPause={playPauseHanlder} playing={playing} onRewind={rewindHandler} onForward={fastForwardHanlder} played = {played} onSeek={seekHanlder} onSeekMouseUp={seekMouseUpHandler} onVolumeSeekUp={volumeSeekUpHandler} OnVolumeChangeHandler={volumeChangeHanlder} mute={muted} onMute={muteHanlder} onMouseSeekDown={onSeekMouseDownHandler} currentTime={formatCurrentTime} duration={formatDuration}  volume={volume} />
        </div>
      </Container>
    </div>
  );
};

export default VideoPlayer;
