import React from "react";
import { Helmet } from "react-helmet";

const TermsandConditions = () => {
  return (
    <>
             <Helmet>
        <title>Terms and Conditions</title>
        </Helmet>
      <section className="min-h-[30vh] flex flex-col items-center justify-center gap-5">
        <h2 className="font-bold text-4xl ">Terms and Conditions</h2>
        <h4 className="font-semibold text-xl">
          Date of Last Revision:July 05,2024
        </h4>
      </section>
      <hr className="w-1/2 left-1/2 transform -translate-x-1/2 absolute" />
      <section className="lg:px-20 md:px-20 px-10 flex flex-col items-center my-5 gap-5">
        <p>
          Welcome to Nilaithal. By using our website and purchasing our
          products, you agree to comply with and be bound by the following terms
          and conditions.
        </p>
        <hr className="w-1/2" />
        <div className="flex items-start flex-col gap-4">
          <ul className="flex flex-col gap-4  ">
            <li>
              A. This document is an electronic record in terms of Information
              Technology Act, 2000 and rules there under as applicable and the
              amended provisions pertaining to electronic records in various
              statutes as amended by the Information Technology Act, 2000. This
              electronic record is generated by a computer system and does not
              require any physical or digital signatures.
            </li>
            <li>
              B. This document is published in accordance with the provisions of
              Rule 3 (1) of the Information Technology (Intermediaries
              guidelines) Rules, 2011 that require publishing the rules and
              regulations, privacy policy and Terms of Use for access or usage
              of domain name <a className="font-bold text-[#6b994b]">www. Nilaithal .com </a>(“Website”), including the
              related mobile site and mobile application (hereinafter referred
              to as “Platform”).{" "}
            </li>
            <li>
            C. The Platform is owned by Nilaithal Private Limited, a company incorporated under the Companies Act, 1956 with its registered office at
No 190 - 192, Hameed Complex Anna Salai,
Chennai 600006,
(hereinafter referred to as "Platform Owner", “we”, “us”,)
            </li>
            <li>
            D. Your use of the Platform and services and tools are governed by the following terms and conditions ("Terms of Use") as applicable to the Platform including the applicable policies which are incorporated herein by way of reference. If You transact on the Platform, You shall be subject to the policies that are applicable to the Platform for such transaction. By mere use of the Platform, You shall be contracting with the Platform Owner and these terms and conditions including the policies constitute Your binding obligations, with Platform Owner. These Terms of Use relate to your use of our website, goods (as applicable) or services (as applicable) (collectively, “Services”). Any terms and conditions proposed by You which are in addition to or which conflict with these Terms of Use are expressly rejected by the Platform Owner and shall be of no force or effect. These Terms of Use can be modified at any time without assigning any reason. It is your responsibility to periodically review these Terms of Use to stay informed of updates. 
            </li>
            <li>
            E. For the purpose of these Terms of Use, wherever the context so requires "you", “your” or "user" shall mean any natural or legal person who has agreed to become a user/buyer on the Platform. 
            </li>
            <li>
            F. ACCESSING, BROWSING OR OTHERWISE USING THE PLATFORM INDICATES YOUR AGREEMENT TO ALL THE TERMS AND CONDITIONS UNDER THESE TERMS OF USE, SO PLEASE READ THE TERMS OF USE CAREFULLY BEFORE PROCEEDING. 
            </li>
            <li>
            G. The use of Platform and/or availing of our Services is subject to the following Terms of Use
            </li>
            <ol className="flex flex-col gap-3 ml-5 list-roman ">
              <li>To access and use the Services, you agree to provide true, accurate and complete information to us during and after registration, and you shall be responsible for all acts done through the use of your registered account on the Platform. </li>
              <li>
              Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials offered on this website or through the Services, for any specific purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law. 
              </li>
              <li>
              Your use of our Services and the Platform is solely and entirely at your own risk and discretion for which we shall not be liable to you in any manner. You are required to independently assess and ensure that the Services meet your requirements. 
              </li>
              <li>
              The contents of the Platform and the Services are proprietary to us and are licensed to us. You will not have any authority to claim any intellectual property rights, title, or interest in its contents. The contents includes and is not limited to the design, layout, look and graphics.  
              </li>
              <li>
              You acknowledge that unauthorized use of the Platform and/or the Services may lead to action against you as per these Terms of Use and/or applicable laws.   
              </li>
              <li>
              You agree to pay us the charges associated with availing the Services. VII. You agree not to use the Platform and/ or Services for any purpose that is unlawful, illegal or forbidden by these Terms, or Indian or local laws that might apply to you.   
              </li>
              <li>
              You agree and acknowledge that website and the Services may contain links to other third party websites. On accessing these links, you will be governed by the terms of use, privacy policy and such other policies of such third party websites. These links are provided for your convenience for provide further information.    
              </li>
              <li>
              You understand that upon initiating a transaction for availing the Services you are entering into a legally binding and enforceable contract with the Platform Owner for the Services.   
              </li>
              <li>
              You shall indemnify and hold harmless Platform Owner, its affiliates, group companies (as applicable) and their respective officers, directors, agents, and employees, from any claim or demand, or actions including reasonable attorneys’ fees, made by any third party or penalty imposed due to or arising out of Your breach of this Terms of Use, privacy Policy and other Policies, or Your violation of any law, rules or regulations or the rights (including infringement of intellectual property rights) of a third party.    
              </li>
              <li>
              In no event will the Platform Owner be liable for any indirect, consequential, incidental, special or punitive damages, including without limitation damages for loss of profits or revenues, business interruption, loss of business opportunities, loss of data or loss of other economic interests, whether in contract, negligence, tort or otherwise, arising from the use of or inability to use the Services, however caused and whether arising in contract, tort, negligence, warranty or otherwise, exceed the amount paid by You for using the Services giving rise to the cause of action or Rupees One Hundred (Rs. 100) whichever is less.    
              </li>
              <li>
              Notwithstanding anything contained in these Terms of Use, the parties shall not be liable for any failure to perform an obligation under these Terms if performance is prevented or delayed by a force majeure event.   
              </li>
              <li>
              These Terms and any dispute or claim relating to it, or its enforceability, shall be governed by and construed in accordance with the law of India.   
              </li>
              <li>
              All disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts in Chennai and Tamil Nadu.   
              </li>
              <li>
              All concerns or communications relating to these Terms must be communicated to us using the contact information provided on this website.   
              </li>
            </ol>
          </ul>
          <div className="flex flex-col gap-4">
            <h5 className="font-semibold text-xl"> Cancellation and Refund policy</h5>
            <p className=" ">
            This cancellation policy outlines about how you can cancel or seek a refund for a product / service that you have purchased through the Platform. Under this policy: 
            </p>
            <ul className="flex flex-col gap-3 ml-5  ">

    <li>
        (i) Cancellations will only be considered if the request is made within 30 days of placing the order. However, cancellation requests may not be entertained if the orders have been communicated to such sellers/merchants listed on the Platform and they have initiated the process of shipping them, or the product is out for delivery. In such an event, you may choose to reject the product at the doorstep.
    </li>
    <li>
        (ii) Nilaithal does not accept cancellation requests for perishable items like flowers, eatables, etc. However, the refund/replacement can be made if the user establishes that the quality of the product delivered is not good.
    </li>
    <li>
        (iii) In case of receipt of damaged or defective items, please report to our customer service team. The request will be entertained once the seller/merchant listed on the Platform has checked and determined the same at its own end. This should be reported within 15 days of receipt of products. In case you feel that the product received is not as shown on the site or as per your expectations, you must bring it to the notice of our customer service within 15 days of receiving the product. The customer service team, after looking into your complaint, will take an appropriate decision.
    </li>
    <li>
        (iv) In case of complaints regarding the products that come with a warranty from the manufacturers, please refer the issue to them.
    </li>
    <li>
        (v) In case of any refunds approved by Nilaithal, it will take 30 days for the refund to be processed to you.
    </li>
</ul>

          </div>

          <div className="flex flex-col gap-4">
            <h5 className="font-semibold text-xl">Returns Policy
            </h5>
            <p className=" ">
            We offer refund / exchange within first 15 days from the date of your purchase. 
            If 15  days have passed since your purchase, you will not be offered return, exchange or refund of any kind. In order to become eligible for a return or an exchange,
            </p>
            <ul className="flex flex-col gap-3 ml-5  ">
            <li>
        (i) The purchased item should be unused and in the same condition as you received it.
    </li>
    <li>
        (ii) The item must have original packaging.
    </li>
    <li>
        (iii) If the item that you purchased was on sale, then the item may not be eligible for a return/exchange. Further, only such items are replaced by us (based on an exchange request) if such items are found defective or damaged. If you need to place a return/exchange request for an eligible product/item (as applicable), you need to send us an email <a href="mailto:milin@india1charger.com" className="text-[#6b994b]">milin@india1charger.com</a>.
    </li>
            </ul>
            <p className=" ">
            You agree that there may be a certain category of products / items that are exempted from returns or refunds. Such categories of the products would be identified to you at the item of purchase. For exchange / return accepted request(s) (as applicable), once your returned product / item is received and inspected by us, we will send you an email to notify you about receipt of the returned / exchanged product. Further. If the same has been approved after the quality check at our end, your request (i.e. return / exchange) will be processed in accordance with our policies. 
            </p>
          </div>

          <div className="flex flex-col gap-4">
            <h5 className="font-semibold text-xl">Shipping Policy </h5>
            <p className="">
            The orders for the user are shipped through registered domestic courier companies and/or speed post only. Order are shipped within 15 to 30 days from the date of the order and/or payment or as per the delivery date agreed at the time of order confirmation and delivering of the shipment, subject to courier company / post office norms. Platform Owner shall not be liable for any delay in delivery by the courier company / postal authority. Delivery of all orders will be made to the address provided by the buyer at the time of purchase. Delivery of our services will be confirmed on your email ID as specified at the time of registration. If there are any shipping cost(s) levied by the seller or the Platform Owner (as the case be), the same is not refundable. For any issues in utilizing our services you may contact our helpdesk on <a className="text-[#6b994b]" href="mailto:milin@india1charger.com">milin@india1charger.com</a>


            </p>
            
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsandConditions;
