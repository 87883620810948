import React, { useState } from "react";
import BannerImg from "../../assets/images/about_page_title.jpg";
import ParallaxImg from "../../assets/images/promo_parallax_front.jpg";
import ParallaxImgBack from "../../assets/images/promo_parallax_back.jpg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Counter from "../../Components/CounterText";
import cta from '../../assets/images/cta.jpg'
import { TeamMembers } from "../../Components/Team";
import "./style.css"
import AccordionItem from "../../Components/Accordion";
import { Helmet } from "react-helmet";

export const Aboutus = () => {
  const [faqs,setFaqs]=useState([
    {
      id:1,
      question: "What products does Nilaithal offer?",
      answer:
        "We offer a range of products including wind turbines, solar panels, electric vehicle (EV) chargers, and India1 Charger - EV charging station aggregator apps.",
        open:true
    },
    {
      id:2,
      question: "How does Nilaithal contribute to sustainability?",
      answer:
        "Nilaithal is dedicated to transforming the energy landscape by harnessing renewable resources, reducing reliance on fossil fuels, and minimizing environmental impact.",
        open:false
    },
    {
      id:3,
      question: "Are Nilaithal's products suitable for residential use?",
      answer:
        "Yes, our products are designed for both residential and commercial applications, offering clean and efficient energy solutions for various needs.",
        open:false
    },
    {
      id:4,
      question: "Is financing available for Nilaithal's products?",
      answer:
        "Yes, we offer financing options to make our products more accessible to customers interested in transitioning to renewable energy.",
        open:false
    },
    {
      id:5,
      question: "Does Nilaithal provide installation services?",
      answer:
        "Yes, we offer installation services for our products to ensure optimal performance and efficiency.",
        open:false
    },
    {
      id:6,
      question: "How can I purchase Nilaithal's products?",
      answer:
        "You can contact us directly through our website or get in touch with our authorized distributors for purchasing inquiries.",
        open:false
    },
  ])

  const handleClick = (clickedId) => {
    setFaqs((prevItems) =>
      prevItems.map((item) =>
        item.id === clickedId ? { ...item, open: true } : { ...item, open: false }
      )
    );
  };


  return (
    <div>
               <Helmet>
        <title>About us</title>
        </Helmet>
      <section
        className="grid grid-cols-1 sm:grid-cols-2  h-full px-10"
        style={{ backgroundColor: "#f9fbf5" }}
      >
        <div className="justify-center flex flex-col h-full">
          <h4 className="sub-text  font-semibold text-xs">About Us</h4>
          <div
            className="flex flex-col justify-between  gap-8"
            
          >
            <h2 className="md:text-4xl text-left font-bold sm:text-3xl">
              We Save The Environment.
            </h2>
            <p className="decription">
             <span style={{color:'#469d2c',fontWeight:'bold'}}>NILAITHAL</span>, is a leading renewable energy company driven by a vision of a world powered by clean, sustainable energy sources. Since our inception, we have been at the forefront of innovation, delivering cutting-edge solutions to address the world's energy challenges. 
            </p>
          </div>
        </div>
        <div className="justify-center flex flex-col h-full">
          <img src={BannerImg} alt="" style={{ objectFit: "contain" }} />
        </div>
      </section>
      <section className="my-5 px-10 flex flex-col justify-center">
        <div className="md:px-10 justify-center flex flex-col h-full items-center ">
          <h4 className="sub-text  font-semibold text-xs">ABOUT COMPANY</h4>
          <div className="flex flex-col justify-between  gap-8 lg:w-3/4 md:w-4/5 sm:w-full">
            <h2 className="md:text-4xl text-center font-bold sm:text-3xl">
              Reasons Why Choose Us
            </h2>
            <p className="decription text-center">
            At <span style={{color:'#469d2c',fontWeight:'bold'}}>NILAITHAL</span>, we are committed to delivering top-quality renewable energy solutions tailored to meet the needs of our customers. Here’s why choosing us makes a difference:
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-5">
          <div className="p-4 flex flex-col gap-4 shadow-md rounded-md px-10 ">
            <div className="flex flex-col">
              <CheckCircleIcon
                className="text-left"
                style={{ textAlign: "center", color: "#469d2c", fontSize: 40 }}
              />
            </div>
            <h5 className="font-bold text-xl">Innovative Technology</h5>
            <p className="text-[16px]">
              <ul className="flex flex-col list-disc gap-y-5">
                <li><b>Cutting-Edge Products:</b> We offer the latest in EV chargers, solar panels, and wind turbines, ensuring high efficiency and reliability.
                </li>
                <li><b>Advanced Research & Development:</b> Our dedicated R&D team continually works on enhancing our product range, incorporating the newest advancements in renewable energy technology.
                </li>
              </ul>
            </p>
          </div>
          <div className="p-4 flex flex-col gap-4 shadow-md rounded-md px-10 ">
            <div className="flex flex-col">
              <CheckCircleIcon
                className="text-left"
                style={{ textAlign: "center", color: "#469d2c", fontSize: 40 }}
              />
            </div>
            <h5 className="font-bold text-xl">Sustainability Commitment</h5>
            <p className="text-[16px]">
              <ul className="flex flex-col list-disc gap-y-5">
                <li><b>Environmental Impact
                :</b>Our products are designed to significantly reduce carbon footprints and promote a sustainable future.
                </li>
                <li><b>Eco-Friendly Solutions
                :</b> We prioritize green energy solutions that are not only effective but also environmentally responsible.
                </li>
              </ul>
            </p>
          </div>
          <div className="p-4 flex flex-col gap-4 shadow-md rounded-md px-10 ">
            <div className="flex flex-col">
              <CheckCircleIcon
                className="text-left"
                style={{ textAlign: "center", color: "#469d2c", fontSize: 40 }}
              />
            </div>
            <h5 className="font-bold text-xl">Comprehensive Support</h5>
            <p className="text-[16px]">
              <ul className="flex flex-col list-disc gap-y-5">
                <li><b>Expert Guidance
                :</b> Our team of specialists is available to provide expert advice and support, helping you choose the best energy solutions for your needs.
                </li>
                <li><b>End-to-End Service
                :</b> From installation to maintenance, we offer comprehensive services to ensure your renewable energy systems operate smoothly.
                </li>
              </ul>
            </p>
          </div>
          <div className="p-4 flex flex-col gap-4 shadow-md rounded-md px-10 ">
            <div className="flex flex-col">
              <CheckCircleIcon
                className="text-left"
                style={{ textAlign: "center", color: "#469d2c", fontSize: 40 }}
              />
            </div>
            <h5 className="font-bold text-xl">Customer-Centric Approach</h5>
            <p className="text-[16px]">
              <ul className="flex flex-col list-disc gap-y-5">
                <li><b>Tailored Solutions
                :</b> We understand that each customer is unique, and we tailor our solutions to fit specific requirements and goals.
                </li>
                <li><b>Satisfaction Guarantee
                :</b> We are dedicated to ensuring complete customer satisfaction with our products and services.
                </li>
              </ul>
            </p>
          </div>
          <div className="p-4 flex flex-col gap-4 shadow-md rounded-md px-10 ">
            <div className="flex flex-col">
              <CheckCircleIcon
                className="text-left"
                style={{ textAlign: "center", color: "#469d2c", fontSize: 40 }}
              />
            </div>
            <h5 className="font-bold text-xl">Future-Ready</h5>
            <p className="text-[16px]">
              <ul className="flex flex-col list-disc gap-y-5">
                <li><b>Scalable Solutions
                :</b> Our energy solutions are designed to grow with you, whether you are scaling up your business or upgrading your home energy system.
                </li>
                <li><b>Innovative Vision
                :</b> We are always looking ahead, ready to embrace new technologies and innovations in the renewable energy sector.
                </li>
              </ul>
            </p>
          </div>
          

        </div>
        <div className="my-5 flex items-center justify-center py-5">
            <p className="text-[16px]">Join us at <span style={{color:'#469d2c',fontWeight:'bold'}}>Nilaithal</span> and experience the difference that top-quality, sustainable energy solutions can make. Embrace a greener future with us and be part of the change for a better tomorrow.</p>
          </div>
      </section>
      <section
        className="grid grid-cols-1 sm:grid-cols-2  h-full py-20 pb-28"
        style={{ backgroundColor: "#f9fbf5" }}
      >
        <div className="md:px-10 justify-center flex flex-col h-full">
        <h2 className="heading-title text-6xl text-left">About Us</h2>
        <h4 className="sub-text text-left">About Company</h4>
        <div className="inner-col my-5 flex flex-col gap-8">
          <h2 className="text-5xl text-left font-bold">We’re the #1 Solar Energy Provider!</h2>
          <p className="description">
          <span style={{color:'#469d2c',fontWeight:'bold'}}>Nilaithal</span> is a forward-thinking green energy company dedicated to revolutionizing the energy landscape with innovative and sustainable solutions. Specializing in EV chargers, solar panels, and wind turbines, we are committed to providing high-quality, eco-friendly products that help reduce carbon footprints and promote renewable energy adoption. Our mission is to lead the way in sustainable living, ensuring a cleaner, greener future for generations to come. At <span style={{color:'#469d2c',fontWeight:'bold'}}>Nilaithal</span>, we combine advanced technology with a strong commitment to environmental stewardship, delivering reliable and efficient energy solutions tailored to meet the needs of our diverse clientele.
          </p>
        </div>
        {/* <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2">
          <div className="flex flex-col gap-4">
           <Counter endValue={96} sign={"K"}/>
           <h5 className="font-bold text-xl">Successfull projects</h5>
           <p className="description">
           The most comprehensive complete system warranty in the solar industry.
           </p>
        
          </div>
          <div className="flex flex-col gap-4">
           <Counter endValue={550} sign={"K+"}/>
           <h5 className="font-bold text-xl">Successfull projects</h5>
           <p className="description">
           The most comprehensive complete system warranty in the solar industry.
           </p>
        
          </div>
        </div> */}
        </div>
        <div className="justify-center flex flex-col relative paralax-img" style={{backgroundImage:`url(${ParallaxImgBack})`, backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center'}}>
          {/* <img src={ParallaxImgBack} alt="" style={{ objectFit: "contain" }} /> */}
          <img src={ParallaxImg} alt="" className="absolute" style={{width:330, height:'auto'}}/>
        </div>
      </section>
      <section className="flex flex-col items-center -mt-16 mb-10">
        <div className="lg:w-3/4 sm:w-full " style={{backgroundColor:'#1b1b1b'}}>
          <section className="flex flex-row flex-wrap">
            <div className="text-white lg:w-3/6 sm:w-full" style={{backgroundImage:`url(${cta})`,backgroundRepeat:'no-repeat', backgroundSize:'cover'}}>
                
            </div>
            <div className="text-white lg:w-3/6 lg:p-10 lg:py-20 sm:w-full sm:p-15 flex-col flex gap-5">
                <h3 className="text-xl font-semibold text-white">Need Help? mail Us Today <a href="mailto:milin@india1charger">milin@india1charger</a></h3>
                <p className="text-white">mail Us Today! We are here to answer your questions 24/7</p>
            </div>
          </section>
        </div>
      </section>
      {/* <section className="my-10">
      <h2 className="heading-title text-6xl text-center">Experts</h2>
        <h4 className="sub-text text-center">Creative Nerds</h4>
        <div className="inner-col my-5 flex flex-col gap-8 px-10">
          <h2 className="title text-5xl  text-center ">Meet Our Team</h2>
          <TeamMembers/>
        </div>
      
      </section> */}
      <section className="grid grid-cols-1 sm:grid-cols-2 gap-4 px-10 " style={{ backgroundColor: "#f9fbf5", }}>
      <div className="justify-center flex flex-col " >
          <h2 className="heading-title text-6xl">FAQ</h2>
          <h4 className="sub-text">About Company</h4>
          <div className="flex flex-col justify-between  gap-8"  style={{ width: "70%" }}>
            <h2 className="title text-5xl text-left">
              Frequently Asked Questions
            </h2>
            <p className="description">
            The most comprehensive complete-system warranty in the solar industry. Hassle-free replacement, repair or reimbursement.
            </p>
            <span>Any Questions? <a href="#" style={{color:'#469d2c'}}>support@greenenergy.com</a></span>
          </div>

        </div>
  <div className="md:px-10 py-10 justify-center flex flex-col">

          {
            faqs.map((item,index)=>(
              <AccordionItem key={item.id} item={item} onClick={handleClick} />
            ))
          }
  </div>
</section>
    </div>
  );
};
