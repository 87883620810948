import { CircularProgress } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Lottie from 'lottie-react';
import PaymentSucessanimationData from '../../json/payement-success.json';
import PaymentPendinganimationData from '../../json/payment-pending.json';
import { CheckBadgeIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { Helmet } from 'react-helmet';

const PaymentConfirmation = () => {

    const [data,setData]=useState('')

    const[loading,setLoading]=useState(true);


    const {id}=useParams()
    // useEffect(async()=>{
    //     try{
    //        await axios.post(`https://backend.nilaithal.com/api/payment/verify/${id}`)
    //         .then((response)=>{
    //                 setData(response)
    //                 console.log(response)
    //         })
    //         .catch((error)=>{
    //             console.log(error)
    //         })
    //     }
    //     catch(error){
    //         console.log(error);
    //     }


    // },[])

    useEffect(() => {
        // window.location.reload()

        const fetchData = async () => {
          try {
            const response = await axios.get(`https://backend.nilaithal.com/api/orders/${id}`);
            setData(response.data); // Access the data property of the response
            console.log(response.data);
            setLoading(false)
          } catch (error) {
            console.log(error);
          }
        };
    
        fetchData();
    
      }, [id]);
  return (
    <section className='min-h-[90vh] flex flex-col items-center justify-center'>
               <Helmet>
        <title>Payment Status</title>
        </Helmet>
        {
            loading ? (<>
            <div className='flex flex-col items-center justify-center gap-5'>
            <CircularProgress variant="solid" color="success" />
            <p className="font-bold text-black text-center z-10 animate-pulse">
              Loading
            </p>
            </div>
            </>):(
                <div className='flex flex-col items-center justify-center gap-4'>
                 {/* <Lottie animationData={data.status =="Paid" ? PaymentSucessanimationData : PaymentPendinganimationData } height={100} width={100}/>
                  */}
                  {
                    data.status =="Paid" ? (<CheckBadgeIcon className='h-40 w-40 fill-green-600 animate-pulse'/>):(<ExclamationCircleIcon className='h-40 w-40 fill-orange-600 animate-pulse'/>)
                  }
                <h2 className={` ${data.status == "Paid"? 'text-green-600':'text-orange-600'} text-4xl font-bold`}>{data?.status}</h2>
                {data.status !="Paid" ?(<h6 className='text-center text-red-600 font-semibold'>Your payment is pending / failed. Please check your mail for confirmation.</h6>):(<h6 className='text-center text-green-600 font-semibold'>Thank You for Shopping with us, Order details has been sent to your mail.</h6>)}
                <h3><b>Order Id:</b> {data?.orderid}</h3>
                <h3><b>Customer Name:</b> {data.name}</h3>
                {data.status =="Paid" ?(<h5><b>Order Amount: </b>₹{data.amount} </h5>):(<h5>Your Payment is being processed..</h5>)}
                
                <a href="/" className='font-bold text-green-500'>Go to Home</a>
            </div>)
        }
        

    </section>
  )
}

export default PaymentConfirmation