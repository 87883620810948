import React, { useEffect, useState } from 'react'
import "./styles.css"
import Logo from '../../assets/images/logo.png'
import { Link, NavLink } from 'react-router-dom'
import DrawerComponent from '../Drawer'

export const DefaultNavbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;

      if (scrollTop > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  return (
   <header className={`px-10 bg-white z-50 ${isScrolled ? 'defaultheader-scrolled' : ''}`} >
    <div className="headercontainer">
    <div className="logo">
        <img src={Logo}/>
    </div>
    <ul className="nav-links">
      <NavLink to={"/"}><li>Home</li></NavLink>
      <NavLink to={"/about"}><li>About us</li></NavLink>
      <NavLink to={"/Services"}><li>Services</li></NavLink>
      {/* <NavLink to={"/blogs"}><li>Blogs</li></NavLink> */}
      <NavLink to={"/products"}><li>Products</li></NavLink>
      <NavLink to={"/partners"}><li>Partners</li></NavLink>
      <NavLink to={"/careers"}><li>Careers</li></NavLink>
      <NavLink to={"/contact"}><li>Contact</li></NavLink>
    </ul>
    <div className="menu">
      <DrawerComponent menuColor={"#000"}/>
    </div>
    </div>
   </header>
  )
}
