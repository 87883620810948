import React, { useEffect, useState } from "react";
import { json, Link, useLocation, useNavigate, useParams } from "react-router-dom";
import ChargerIMg1 from "../../assets/images/AC1.png";
import ChargerIMg2 from "../../assets/images/AC2.png";
import ReactImageMagnify from "react-image-magnify";
import "./style.css";
import Magnifier from "react-magnifier";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import {
  ChatBubbleOvalLeftIcon,
  ShareIcon,
  Cog6ToothIcon,
  CogIcon,
  BoltIcon,
  TruckIcon,
  ShieldCheckIcon,
  TrophyIcon,
  MinusIcon,
  PlusIcon,
} from "@heroicons/react/24/solid";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
const ProductDetails = () => {
  const { productname,  } = useParams();


  const location = useLocation();

  const {productId} =location.state;

;
  // const data =[
  //     {
  //       id: "ac1",
  //       name: "AC 3.3 kW charger with 3 to 5 universal sockets per one Smart Control Unit",
  //       voltage: "3.3 KW",
  //       price: 78000,
  //       currency: "INR",
  //       description: "A reliable AC charger for home use.",
  //       brand: "ChargerBrand",
  //       category: "AC",
  //       stock: 100,
  //       main_image: "https://ev.ideassion.in/wp-content/uploads/2024/04/image-removebg-preview-5-176x300.png",
  //       images: [
  //         ChargerIMg1,
  //         ChargerIMg2,
  //         "https://ev.ideassion.in/wp-content/uploads/2024/04/image-removebg-preview-4-198x300.png"
  //       ]
  //     },
  //     {
  //       id: "ac2",
  //       name: "AC 15 kW charger with Dual Type 2 connector (7.4kw each) / (IEC 62196)",
  //       voltage: "15KW",
  //       price: 92450,
  //       currency: "INR",
  //       description: "A powerful AC charger for international use.",
  //       brand: "PowerPlus",
  //       category: "AC",
  //       stock: 50,
  //       main_image: "https://ev.ideassion.in/wp-content/uploads/2024/04/image-removebg-preview-8-134x300.png",
  //       images: [
  //         "https://ev.ideassion.in/wp-content/uploads/2024/04/image-removebg-preview-8-134x300.png",
  //         ChargerIMg1,
  //         ChargerIMg2,

  //       ]
  //     },
  //     {
  //       id: "dc1",
  //       name: "30 kW DC (Fast) charger with single connector (CCS2)",
  //       voltage: "30KW",
  //       price: 57700,
  //       currency: "INR",
  //       description: "A compact DC charger for automotive use.",
  //       brand: "AutoCharge",
  //       category: "DC",
  //       stock: 75,
  //       main_image: "https://ev.ideassion.in/wp-content/uploads/2024/04/image-removebg-preview-4-198x300.png",
  //       images: [
  //         ChargerIMg1,
  //         ChargerIMg2,
  //         "https://example.com/images/dc1_side.jpg",
  //         "https://example.com/images/dc1_back.jpg"
  //       ]
  //     },
  //     {
  //       id: "dc2",
  //       name: "60 kW DC (Fast) charger with single connector (CCS2)",
  //       voltage: "60KW",
  //       price: 85000,
  //       currency: "INR",
  //       description: "A high-power DC charger for trucks and heavy vehicles.",
  //       brand: "TruckPower",
  //       category: "DC",
  //       stock: 30,
  //       main_image: "https://ev.ideassion.in/wp-content/uploads/2024/04/image-removebg-preview-4-198x300.png",
  //       images: [
  //         ChargerIMg1,
  //         ChargerIMg2,
  //         "https://example.com/images/dc2_front.jpg",
  //         "https://example.com/images/dc2_side.jpg",
  //         "https://example.com/images/dc2_back.jpg"
  //       ]
  //     },
  //     {
  //       id: "pc1",
  //       name: "AC 7.4 kW charger with Type 2 connector with (IEC 62196) and 3.3 universal socket",
  //       voltage: "7.4KW",
  //       price: 78750,
  //       currency: "INR",
  //       description: "A handy portable charger for mobile devices.",
  //       brand: "TravelCharge",
  //       category: "Portable",
  //       stock: 150,
  //       main_image: "https://ev.ideassion.in/wp-content/uploads/2024/04/image-removebg-preview-9.png",
  //       images: [
  //         ChargerIMg1,
  //         ChargerIMg2,
  //         "https://ev.ideassion.in/wp-content/uploads/2024/04/image-removebg-preview-9.png",
  //       ]
  //     },
  //     {
  //       id: "pc2",
  //       name: "AC 22 kW charger with Type 2 connector (IEC 62196)",
  //       voltage: "9V",
  //       price: 68250,
  //       currency: "INR",
  //       description: "A fast-charging portable charger for smartphones and tablets.",
  //       brand: "FastCharge",
  //       category: "Portable",
  //       stock: 100,
  //       main_image: "https://ev.ideassion.in/wp-content/uploads/2024/04/image-removebg-preview-10.png",
  //       images: [
  //         ChargerIMg1,
  //         ChargerIMg2,
  //         "https://ev.ideassion.in/wp-content/uploads/2024/04/image-removebg-preview-10.png"
  //       ]
  //     },
  //   ]

  // const productData = data.find(item=>item.id == productid)

  const url = "https://backend.nilaithal.com";
  const navigate = useNavigate();

  const [data, setData] = useState("");
  const [quantity, setQuantity] = useState(1);
  const[cookieData,setCookieData]=useState('')

  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetchData();
    const cartlist = JSON.parse(sessionStorage.getItem('cart')) || [];

    const productInCart = cartlist.find(item =>item.productId === productId);

    if(productInCart){
      setQuantity(productInCart.quantity);
    }

    // console.log('cartlist',cartlist)



    // const cartCookies = Cookies.get('cart')
    // const cartItems = sessionStorage.getItem('cart')
    // if(cartItems){
    //   try{
    //     setProducts(JSON.stringify(cartItems))

    //   }
    //   catch(error){
    //     console.error("Error Passing cart Cookies",error)
    //   }

    // }
  }, [productId]);
  

  const fetchData = async () => {
    try {
      const options = {
        method: "GET",
        url: `https://backend.nilaithal.com/api/products/${productId}?populate=*`,
      };
      const response = await axios.request(options);
   
      setData(response.data.data);
      // setCurrentImage(data?.image[0]?.url);
      setCurrentImage(response.data.data.image[0]?.url);
      setLoading(false);
    } catch (error) {
      console.log("Error ", error);
      setError(error);
      setLoading(true);
      alert(error);
    }
  };

  const [currentImage, setCurrentImage] = useState("");
  // const handleAddCart=()=>{
  //   setProducts({
  //     id:productId,
  //     quantity:quantity,
  //   })
  //   Cookies.set({
  //     id:productId,
  //     quantity:quantity,
  //   })

  //   console.log
  //   // navigate('/checkout',{state:{
  //   //   id:productId,
  //   //   quantity:quantity,
  //   // }})

  // }

  if(error){
    return <><div className='min-h-[50vh] flex flex-col items-center justify-center gap-2'>
      <ExclamationTriangleIcon className='h-16 w-16  stroke-gray-300'/>
      <h4 className='text-center text-xl font-semibold text-gray-500'>{error}</h4>
      </div></>
  }

  const handleAddCart = () => {
    let cartItems = sessionStorage.getItem("cart");
    let parsedCart = JSON.parse(cartItems);
    if (!cartItems) {
      sessionStorage.setItem(
        "cart",
        JSON.stringify([
          {
            productId: productId,
            quantity: quantity,
            name: data?.name,
            price:data?.price,
            voltage: data?.voltage,
            image: data?.image[0].url,
          },
        ])
      );
    } else {
      const productIndex = parsedCart.findIndex(
        (product) => product.productId === productId
      );
      if (productIndex > -1) {
        parsedCart[productIndex].quantity = quantity;

        let updatedCart = JSON.stringify(parsedCart);
       
        sessionStorage.setItem("cart", updatedCart);
      } else {
        parsedCart.push({
          productId: productId,
          quantity: quantity,
          name: data?.name,
          price:data?.price,
          voltage: data?.voltage,
          image: data?.image[0].url,
        });
      }

      let updatedCart = JSON.stringify(parsedCart);
      sessionStorage.setItem("cart", updatedCart);
    }

    navigate('/checkout')
  
  };

;
  return (
    <>
      {loading ? (
        <>
          <div className="min-h-screen items-center flex flex-col gap-2 justify-center">
            <CircularProgress variant="solid" color="success" />
            <p className="font-bold text-black text-center z-10 animate-pulse">
              Loading
            </p>
          </div>
        </>
      ) : (
        <section className="grid grid-cols-1 xl:grid-cols-2 md:grid-cols-1 min-h-screen  px-10 py-10">
          <div className="flex flex-col items-center justify-center gap-3.5">
            <div className="h-[500px] w-[300px] md:w-[500px] xl:w-[500px] bg-white">
              {/* <ReactImageMagnify
            {
                ...{
                    smallImage:{
                        alt:productData.name,
                        isFluidWidth:false,
                        src:productData.main_image,
                        width:500,
                        height:500,
                        obj
                    },
                    largeImage:{
                        src:productData.main_image,
                        width:750,
                        height:750
                    }
                }
            }/> */}
              <Magnifier
                src={`${url}${currentImage}`}
                width={"100%"}
                height={"100%"}
                className="h-full w-full object-contain"
              />
              {/* <img src={currentImage} alt="" className='h-full w-full object-contain' /> */}
            </div>
            <div className="flex gap-5 items-center">
              {data.image.map((item, index) => (
                <div
                  className="h-[100px] w-[100px] border shadow-lg rounded-lg"
                  onClick={() => setCurrentImage(item.url)}
                >
                  <img
                    src={`${url}${item.url}`}
                    alt=""
                    className="h-full w-full object-contain"
                  />
                </div>
              ))}
            </div>
          </div>
          <div>
            <div className="flex flex-col gap-y-5">
              <h2 className="font-bold text-2xl md:text-4xl xl:text-4xl leading-normal">
                {data?.name}
              </h2>
              <div className="flex flex-row gap-5 items-center">
                <p className="text-xs font-bold">Nilaithal's Choice</p>
                <p className="text-xs font-semibold">
                  1K+ bought in past month
                </p>
              </div>
              <hr />
              <div className="my-2">
                <p className="text-justify">{data?.description}</p>
              </div>
              <div className="flex flex-row gap-5 items-center">
                <p className="text-2xl text-red-600">-11%</p>
                <h4 className="text-3xl font-bold">
                  ₹ {data.price.toLocaleString()}
                </h4>
              </div>
              <p>
                M.R.P: <span className="line-through">₹ 79,990</span>
              </p>
              <p>Inclusive of all taxes</p>
              <p>
                <b>EMI</b> start at ₹3,442. No Cost EMI available
              </p>

              <ul className="flex flex-row gap-5">
                <li className="text-xs text-center flex flex-col gap-2 items-center  w-2/12">
                  <PublishedWithChangesIcon
                    className="bg-white p-1 rounded-full  text-[#6b994b] "
                    fontSize="large"
                  />

                  <span className="font-semibold">
                    7days Service Center Replacement
                  </span>
                </li>
                <li className="text-xs text-center flex flex-col gap-2 items-center">
                  <div>
                    <TruckIcon className="h-9 bg-white rounded-full p-1 fill-[#6b994b]" />
                  </div>
                  <span className="font-semibold">Free Delivery</span>
                </li>
                <li className="text-xs text-center flex flex-col gap-2 items-center">
                  <div>
                    <ShieldCheckIcon className="h-9 bg-white rounded-full p-1 fill-[#6b994b]" />
                  </div>
                  <span className="font-semibold">Waranty Policy</span>
                </li>
                <li className="text-xs text-center flex flex-col gap-2 items-center">
                  <div>
                    <TrophyIcon className="h-9 bg-white rounded-full p-1 fill-[#6b994b]" />
                  </div>
                  <span className="font-semibold">Top Brand</span>
                </li>
              </ul>

              <div className="h-full  flex flex-row  items-center justify-center md:gap-16 xl:gap-32 md:justify-normal xl:justify-normal">
                {/* <button className='bg-black text-xs text-white font-semibold py-2 px-10 rounded-lg hover:bg-[#6b994b]' onClick={()=>addToCart(productData)}>Add to Cart</button> */}
                <div className="flex flex-row items-center gap-5">
                  <button
                    className="bg-gray-200 px-1 rounded-sm hover:bg-green-400 group"
                    onClick={() => setQuantity((prevcount) => prevcount - 1)}
                    disabled={quantity < 2 ? true : false}
                  >
                    <MinusIcon className="h-5 w-6 group-hover:fill-white" />
                  </button>
                  {quantity}
                  <button
                    className="bg-gray-200 px-1 rounded-sm hover:bg-green-400 group"
                    onClick={() => setQuantity((prevcount) => prevcount + 1)}
                  >
                    <PlusIcon className="h-5 w-6 group-hover:fill-white" />
                  </button>
                </div>
                <a onClick={() => handleAddCart()}>
                  {" "}
                  <button className="bg-[#6b994b] text-xs py-2 px-10 font-semibold text-white rounded-lg hover:bg-black">
                    Buy Now
                  </button>
                </a>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ProductDetails;
